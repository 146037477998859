import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCategoriesAsync, fetchDocumentsAsync } from "./documentsApi";
import merge from "lodash.merge";

export const fetchDocumentsThunk = createAsyncThunk(
  "documents/fetchDocuments",
  async (options, thunkAPI) => {
    const state = thunkAPI.getState();
    const { id } = state.estates.currentEstate;
    // add estateId to options
    const defaultOptions = {
      params: {
        filter: {
          estateId: id,
        },
      },
    };

    const response = await fetchDocumentsAsync(merge(defaultOptions, options));
    return response.data;
  }
);

export const fetchDocumentCategoriesThunk = createAsyncThunk(
  "documents/fetchCategories",
  async (options, thunkAPI) => {
    const response = await fetchCategoriesAsync(options);
    return response.data;
  }
);
