import React from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import validationScheme from "./NewPasswordValidationScheme";

import { apiClient } from "../../apiClient/apiClient";

const styles = (theme) => ({
  layout: {
    paddingTop: theme.spacing(8),
    width: "auto",
    display: "block", // Fix IE11 ticket.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    margin: theme.spacing(),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE11 ticket.
    marginTop: theme.spacing(),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
});

// Wrap our form with the using withFormik HoC
const formControl = {
  // Transform outer props into form values
  mapPropsToValues: (props) => ({
    newPassword: "",
  }),
  // Add a custom validation function (this can be async too!)
  validationSchema: validationScheme,
  // Submission handler
  handleSubmit: async (values, { props, setSubmitting, resetForm }) => {
    const { newPassword } = values;
    const token = props.match.params.id;
    setSubmitting(true);

    try {
      const response = await apiClient().post("accounts/_finishPasswordReset", {
        token: token,
        password: newPassword,
      });
      if (response.status === 200) {
        resetForm();
        setSubmitting(false);
        props.history.push("/login");
      }
    } catch (err) {
      console.error(err);
    }
  },
};

const NewPasswordForm = (props) => {
  const { classes, values, handleChange, handleSubmit, touched, errors } =
    props;

  return (
    <React.Fragment>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <form className={classes.form} onSubmit={handleSubmit}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="newPassword">Neues Passwort</InputLabel>
              <Input
                type="password"
                error={touched.newPassword && errors.newPassword ? true : false}
                id="newPassword"
                name="newPassword"
                value={values.newPassword}
                onChange={handleChange}
                autoComplete="newPassword"
              />
            </FormControl>

            <Button
              type="submit"
              fullWidth
              variant="raised"
              color="primary"
              className={classes.submit}
            >
              Passwort ändern
            </Button>
          </form>
        </Paper>
      </main>
    </React.Fragment>
  );
};

NewPasswordForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  withRouter,
  withFormik(formControl),
)(NewPasswordForm);
