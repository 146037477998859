import { withStyles } from "@material-ui/core/styles";
import { FolderList } from "../Common";
import { compose } from "recompose";
import { withTicketsFiltersAndSetRead } from "../../containers/TicketListContainer/TicketListContainer";
import { nonOptimalStates } from "../../functions";


const styles = (theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
});

const noBG = (theme) => ({
  root: {
    width: "100%",
  },
});

const hasBackground = (props) => Boolean(!props.light);

const BgDecision = nonOptimalStates([
  {
    when: hasBackground,
    render: withStyles(styles)(FolderList),
  },
])(withStyles(noBG)(FolderList));

export default compose(
  withTicketsFiltersAndSetRead
)(BgDecision);
