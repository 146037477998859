import { apiClient } from "./apiClient";

export default async function getAttachmentAsset(fileName) {
  const assetUrl = `${process.env.REACT_APP_API}${fileName}`;
  if (
    assetUrl &&
    assetUrl.includes("/attachments/") &&
    assetUrl.toLowerCase().endsWith(".pdf")
  ) {
    const signatureUrl = assetUrl.replace(
      "/attachments/",
      "/attachments/_signature/"
    );

    try {
      const signatureResponse = await apiClient().get(signatureUrl, {});
      // console.log("signatureObject", signatureResponse);

      const { data } = signatureResponse;
      // console.log("data", data);

      const { signature } = data;

      const signaturedUrl = `${assetUrl}?sig=${signature}`;
      // console.log("signaturedUrl", signaturedUrl);

      return signaturedUrl;
    } catch (error) {
      console.error(error);
    }
  }
  return assetUrl;
}
