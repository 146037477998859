import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { InsertDriveFile as FileIcon } from "@material-ui/icons";

import { getImageSRC } from "../../functions/assets";
import { ImageDetailModal } from "../Modal/ImageDetailModal";
import { formatTime } from "../../functions";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  withStyles,
} from "@material-ui/core";
import { PdfModal } from "../Modal/PdfModal";

// check naive if the source file is a pdf
const isPDF = (imageSourceForModal) =>
  imageSourceForModal.toLowerCase().endsWith(".pdf");
/**
 *
 * @param {*} param0
 */
const MessageListItemContainer = ({ document, classes }) => {
  const { author, content, createdAt, attachments } = document;

  const [imageSourceForModal, setImageSourceForModal] = useState(undefined);

  const closeModal = () => setImageSourceForModal(undefined);

  // messages with line breaks from PWA/App (this App)
  let messageRows = content.split("<br />");

  // messages with line breaks from Verwalter
  if (content.includes("\n")) {
    messageRows = content.split("\n");
  }

  return (
    <div className={classes.messageItem}>
      {messageRows &&
        messageRows.length &&
        messageRows.map((message, index) => {
          return (
            <div key={`${index}`} className={classes.messageItemText}>
              {message}
            </div>
          );
        })}

      <Typography variant="caption">{formatTime(createdAt)}</Typography>
      <Typography variant="caption">{author}</Typography>
      <List dense={true}>
        {attachments.map((file) => (
          <ListItem
            key={file.id}
            onClick={() => setImageSourceForModal(getImageSRC(file.filename))}
          >
            <ListItemIcon>
              <FileIcon />
            </ListItemIcon>
            <ListItemText>{file.name}</ListItemText>
          </ListItem>
        ))}
      </List>
      {imageSourceForModal && isPDF(imageSourceForModal) ? (
        <PdfModal source={imageSourceForModal} closeModal={closeModal} />
      ) : (
        <ImageDetailModal
          imageSourceForModal={imageSourceForModal}
          closeModal={closeModal}
        />
      )}
    </div>
  );
};

const messageStyle = (theme) => ({
  subtitle: {
    fontWeight: "bold",
  },
  messageItemText: {
    fontSize: "1em",
    fontFamily: theme.typography.fontFamily,
    minHeight: "1em",
  },
  messageItem: {
    marginBottom: theme.spacing(),
  },
});

export const MessageListItem = withStyles(messageStyle)(
  MessageListItemContainer,
);
