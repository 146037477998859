import React from "react";
import { Switch } from "react-router-dom";

import ProtectedRoute from "../../components/ProtectedRoute";
import PublicRoute from "../../components/PublicRoute";

import HomeContainer from "./../HomeContainer/HomeContainer";
import DocumentContainer from "../DocumentListContainer/DocumentListContainer";
import TicketListContainer, {
  route as ticketsListRoute,
} from "../TicketListContainer/TicketListContainer";
import TicketDetailContainer from "../TicketDetailContainer/TicketDetailContainer";
import TicketFormContainer from "../TicketFormContainer/TicketFormContainer";
import PropertyManagementContainer from "./../PropertyManagementContainer/PropertyManagementContainer";
import AcceptInviteContainer from "../AcceptInviteContainer/AcceptInviteContainer";
import PasswordLostContainer from "../PasswordLostContainer/PasswordLostContainer";
import PasswordResetContainer from "../NewPasswordContainer/NewPasswordView";
import UpdatePasswordContainer from "../UpdatePasswordContainer/UpdatePasswordContainer";
import SignInContainer from "../SignInContainer/SignInContainer";
import PostingDetailsContainer from "../PostingContainer/PostingDetailContainer";
// Profile
import ProfileContainer from "./../ProfileContainer/ProfileContainer";
import AccountContainer from "./../ProfileContainer/AccountContainer";
import PrivacyContainer from "./../ProfileContainer/PrivacyContainer";
import MyDataContainer from "./../ProfileContainer/MyDataContainer";
import DataPrivacyContainer from "./../ProfileContainer/DataPrivacyContainer";
import TermsOfUseContainer from "./../ProfileContainer/TermsOfUseContainer";
import ImprintContainer from "./../ProfileContainer/ImprintContainer";
import ContactContainer from "./../ProfileContainer/ContactContainer";
import TandcContainer from "./../ProfileContainer/TandcContainer";

const Routes = () => {
  return (
    <Switch>
      {/* Sign-in */}
      <PublicRoute
        exact
        path={SignInContainer.route}
        component={SignInContainer}
      />

      {/* Password Lost */}
      <PublicRoute
        exact
        path={PasswordLostContainer.route}
        component={PasswordLostContainer}
      />

      {/* Password Reset */}
      <PublicRoute
        exact
        path={PasswordResetContainer.route}
        component={PasswordResetContainer}
      />

      {/* Accept Invitation */}
      <PublicRoute
        exact
        path={AcceptInviteContainer.route}
        component={AcceptInviteContainer}
      />

      {/* Protected Area */}

      {/* Home "/" */}
      <ProtectedRoute
        exact
        path={HomeContainer.route}
        component={HomeContainer}
      />

      {/* Documents "/documents" */}
      <ProtectedRoute
        exact
        path={DocumentContainer.route}
        component={DocumentContainer}
      />

      {/* Posting detail "/postings/:postingId" */}
      <ProtectedRoute
        exact
        path={PostingDetailsContainer.route}
        component={PostingDetailsContainer}
      />

      {/* Ticket "/tickets" */}
      <ProtectedRoute
        exact
        path={ticketsListRoute}
        component={TicketListContainer}
      />
      {/* Ticket add "/tickets/new" */}
      <ProtectedRoute
        exact
        path={TicketFormContainer.route}
        component={TicketFormContainer}
      />
      {/* Ticket slave "/tickets/:ticketId" */}
      <ProtectedRoute
        exact
        path={TicketDetailContainer.route}
        component={TicketDetailContainer}
      />

      {/* Property Management  */}
      <ProtectedRoute
        exact
        path={PropertyManagementContainer.route}
        component={PropertyManagementContainer}
      />

      {/* Profile "/profile" */}
      <ProtectedRoute
        exact
        path={ProfileContainer.route}
        component={ProfileContainer}
      />
      {/* Profile "/profile/account" */}
      <ProtectedRoute
        exact
        path={AccountContainer.route}
        component={AccountContainer}
      />
      {/* Profile "/profile/privacy" */}
      <ProtectedRoute
        exact
        path={PrivacyContainer.route}
        component={PrivacyContainer}
      />
      {/* Profile "/profile/terms-of-use" */}
      <ProtectedRoute
        exact
        path={TermsOfUseContainer.route}
        component={TermsOfUseContainer}
      />
      {/* Profile "/profile/my-data" */}
      <ProtectedRoute
        exact
        path={MyDataContainer.route}
        component={MyDataContainer}
      />
      {/* Profile "/profile/data-privacy" */}
      <ProtectedRoute
        exact
        path={DataPrivacyContainer.route}
        component={DataPrivacyContainer}
      />
      {/* Profile "/profile/imprint" */}
      <ProtectedRoute
        exact
        path={ImprintContainer.route}
        component={ImprintContainer}
      />
      {/* Profile "/profile/contact" */}
      <ProtectedRoute
        exact
        path={ContactContainer.route}
        component={ContactContainer}
      />
      {/* Profile "/profile/tandc" */}
      <ProtectedRoute
        exact
        path={TandcContainer.route}
        component={TandcContainer}
      />

      <ProtectedRoute
        exact
        path={UpdatePasswordContainer.route}
        component={UpdatePasswordContainer}
      />

      {/* default route */}
      <ProtectedRoute component={HomeContainer} />
    </Switch>
  );
};

export default Routes;
