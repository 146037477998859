const SET_TICKET_LIST_FILTER = "tickets/SET_TICKET_LIST_FILTER";

export const setTicketListFilterId = (filterId) => ({
  type: SET_TICKET_LIST_FILTER,
  payload: filterId,
});

// [
//   { id: "ALL", name: "Kein Filter" },

//   { id: "NEW", name: "Offen" },
//   { id: "ACCEPTED", name: "Freigegeben" },
//   { id: "IN_PROGRESS", name: "In Bearbeitung" },
//   { id: "WORK_DONE", name: "Ausgeführt" },
//   { id: "SOLVED", name: "Abgeschlossen" }
// ];

export const reducer = (state = "ALL", action) => {
  switch (action.type) {
    case SET_TICKET_LIST_FILTER:
      return action.payload;

    default:
      return state;
  }
};
