import React, { Component } from "react";
import { withFormik } from "formik";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ticketScheme from "./TicketValidationScheme";
import ServiceTypeSelector from "./TicketFormSteps/ServiceTypeSelector";
import DetailsSection from "./TicketFormSteps/DetailsSection";
import ImageUploadSection from "./TicketFormSteps/ImageUploadSection";
import { withCreateNewTicket } from "../../modules/ticket/ticketForm/ticketFormActions";
import { withShowNotificationMessage } from "../../modules/uiState";
import { route as ticketsListRoute } from "../TicketListContainer/TicketListContainer";
import {
  relationTypes,
  withCurrentEstate,
  withCurrentUser,
  // withServiceTypes,
} from "../../functions";
import { ButtonBase } from "@material-ui/core";
import { injectIntl } from "react-intl";
import { getServiceTypeLanguageIdByName } from "../../modules/ticket/ticketConstants";

/**
 *
 * @param {*} theme
 */
const styles = (theme) => ({
  root: {
    display: "block",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
  switchContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
  switchLabes: {
    // lineHeight: theme.spacing( * 30
    paddingBottom: theme.spacing(1.8),
    paddingTop: theme.spacing(1.8),
  },
  subtitle: {
    textAlign: "end",
    paddingRight: theme.spacing(),
  },
  subtitleService: {
    marginTop: "auto",
  },
  section: {
    marginBottom: theme.spacing(2),
  },
  width100: {
    width: "100%",
  },
  previousWidth100: {
    width: "100%",
    marginBottom: "1em",
  },
  step: {
    marginBottom: "1rem",
  },
  form: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
  title: {
    margin: `${theme.spacing(4)}px 0 ${theme.spacing(2)}px`,
  },
  list: {
    marginTop: "1rem",
    // backgroundColor: theme.palette.background.paper
  },
  textField: {
    width: "100%",
  },
  displayNone: { display: "none" },
  marginRight: { marginRight: theme.spacing() },
});

/**
 *
 * @param {*} props
 */
const ServiceType = injectIntl((props) => {
  const service = props.service || Object.create(null);
  const formatMessage = props.intl.formatMessage;
  const serviceTypeNameTranslated = formatMessage({
    id: getServiceTypeLanguageIdByName(service.name),
  });

  return (
    <Typography className={props.classes.subtitleService} variant="caption">
      {serviceTypeNameTranslated}
    </Typography>
  );
});

/**
 *
 */
const ServiceTypeName = withStyles(styles)(ServiceType);

/**
 *
 * @param {*} param0
 */
const FormHeader = injectIntl(
  ({ values, classes, intl: { formatMessage }, ...rest }) => {
    const serviceType = parseInt(values.serviceType, 10);
    return (
      <div
        className={classes.header}
        style={{ width: "100%", marginLeft: 0, marginRight: 0 }}
      >
        <Typography variant="h5">
          {formatMessage({ id: "ticket.create.new.title" })}
        </Typography>
        {rest.page > 1 && rest.findServiceType && (
          <ServiceTypeName service={rest.findServiceType(serviceType)} />
        )}
      </div>
    );
  },
);

/**
 *
 */
const FormHeaderTitle = withStyles(styles)(FormHeader);

/**
 *
 * @param {*} number
 */
const setPage = (number) => (state) => ({ page: state.page + number });

/**
 * Inner form component which receives our form's state and updater methods as props
 */
class InnerForm extends Component {
  static Page = ({ children }) => children;

  state = {
    page: 1,
    values: this.props.values,
  };

  next = (ev) => {
    ev.preventDefault();
    this.setState(setPage(Number(+1)));
  };

  previous = (ev) => {
    ev.preventDefault();
    if (this.state.page > 1) {
      this.setState(setPage(Number(-1)));
    }
  };

  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleSubmit,
      isSubmitting,
      setFieldValue,
      classes,
      // serviceTypes,
      currentEstate,
      intl: { formatMessage },
    } = this.props;

    const serviceTypes = (currentEstate && currentEstate.serviceTypes) || [];
    const isLastPage = this.state.page === 3;

    values.estateId = currentEstate.id;

    return (
      <FormControl className={classes.root}>
        <form
          onSubmit={handleSubmit}
          // noValidate
          autoComplete="off"
          encType="multipart/form-data"
        >
          <section className={classes.section}>
            <header className={classes.header}>
              <FormHeaderTitle
                page={this.state.page}
                values={values}
                findServiceType={(i) =>
                  serviceTypes.find((type) => type.id === i)
                }
              />
            </header>
            <Typography className={classes.subtitle}>
              {`${formatMessage({ id: "ticket.create.new.step" })} ${
                this.state.page
              } / 3`}
            </Typography>
          </section>
          <div>
            {(() => {
              switch (this.state.page) {
                case 1:
                  return (
                    <ServiceTypeSelector
                      serviceTypes={serviceTypes}
                      onNext={this.next}
                      handleSetFieldValue={(field, value) => {
                        setFieldValue(field, String(value));
                      }}
                    />
                  );
                case 2:
                  return (
                    <DetailsSection
                      classes={classes}
                      error={errors}
                      touched={touched}
                      values={values}
                      handleChange={handleChange}
                    />
                  );
                case 3:
                  return (
                    <ImageUploadSection
                      classes={classes}
                      values={values}
                      handleSetFieldValue={(field, value) => {
                        setFieldValue(field, value);
                      }}
                    />
                  );
                default:
                  return null;
              }
            })()}

            <div className={classes.form}>
              {errors.subject ? (
                <ButtonBase
                  onClick={this.previous}
                  variant="text"
                  className={classes.width100}
                >
                  <Typography variant="body1" style={{ color: "red" }}>
                    {errors.subject}
                  </Typography>
                </ButtonBase>
              ) : null}
              {isLastPage ? (
                <React.Fragment>
                  <Button
                    variant="text"
                    className={classes.previousWidth100}
                    onClick={this.previous}
                  >
                    {formatMessage({
                      id: "ticket.create.form.button.label.back",
                    })}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.width100}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {formatMessage({
                      id: "ticket.create.form.button.label.submit",
                    })}
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {this.state.page > 1 && (
                    <Button
                      variant="text"
                      className={classes.previousWidth100}
                      onClick={this.previous}
                    >
                      {formatMessage({
                        id: "ticket.create.form.button.label.back",
                      })}
                    </Button>
                  )}
                  {this.state.page > 1 && (
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.width100}
                      onClick={this.next}
                    >
                      {formatMessage({
                        id: "ticket.create.form.button.label.next",
                      })}
                    </Button>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </form>
      </FormControl>
    );
  }
}

// Wrap our form with the using withFormik HoC
const formControl = {
  // Transform outer props into form values
  mapPropsToValues: (props) => {
    return {
      estateId: props.currentEstate.id,
      // subject: "",
      body: "",
      place: "",
      serviceType: 0,
      capture: [],
      anonymous: false,
      private: true,
    };
  },
  // Add a custom validation function (this can be async too!)
  validationSchema: ticketScheme,

  // Submission handler
  handleSubmit: (values, { props, resetForm, ...rest }) => {
    const { estateId, subject, body, place, serviceType, capture, anonymous } =
      values;
    const {
      intl: { formatMessage },
    } = props;

    // build ticket
    const ticket = {
      estateId,
      subject,
      // always allow messaging
      messagesEnabled: true,
      anonymous,
      body: `
        ${body || ""}
        ---
        ${place || ""}
      `,
    };

    /**
     * This form will not be validated during its progress and React's
     * internals. Therefore, it has to be evaluated during submit way.
     */
    if (!Boolean(subject)) {
      rest.setFieldError(
        "subject",
        formatMessage({ id: "ticket.create.submit.error.no_subject" }),
      );
      rest.setSubmitting(false);
      return void 0;
    }

    /**
     * Messages which are private can be seen by
     * the estate and the person who send of the
     * ticket.
     *
     * you need
     * - accessibleByPersonIds (mostly an array with only one user id)
     * - publishingMode = "UNPUBLISHED"
     */
    if (Boolean(values.private)) {
      // The current user id
      ticket.accessibleByPersonIds = [props.currentUser.id];
      // make this a private message
      ticket.publishingMode = "UNPUBLISHED";
      // clean up in case it has been filled
      ticket.accessibleByTypes = [];
    }

    /**
     * Public Messages to groups who have the correct permissions
     *
     * you need
     * - accessibleByTypes (an array with different types of permissions)
     * - publishingMode = "PUBLISHED"
     */
    if (!Boolean(values.private)) {
      ticket.accessibleByTypes = relationTypes.map(({ id }) => id);
      // make this a public message
      ticket.publishingMode = "PUBLISHED";
      // clean up
      ticket.accessibleByPersonIds = [];
    }

    // If someone chooses to send tickets anonymous, you need to add the livingUnitId for reference
    if (Boolean(values.anonymous) && props.currentUser.relations) {
      const livingUnitIds = props.currentUser.relations
        // find all estates in question
        .filter((estate) => estateId === estate.estateId)
        // map all livingUinitId
        // usually this should only be one livingUinitId
        .map(({ livingUnitId }) => livingUnitId);

      // attach the correct livingUnits to this post
      ticket.livingUnitIds = livingUnitIds;
    }

    // if it's not a generic service
    if (Number(serviceType)) {
      ticket.serviceType = { id: serviceType };
    }

    ticket.attachments = capture;

    // make api request
    props
      .createNewTicket(ticket)
      .then(() => {
        resetForm();
        props.showNotificationMessage(
          formatMessage({ id: "ticket.create.submit.success" }),
        );
      })
      .catch((err) => {
        rest.setSubmitting(false);
      })
      .finally((_) => props.history.push(ticketsListRoute));
  },
};

export default compose(
  injectIntl,
  withRouter,
  withCreateNewTicket,
  withShowNotificationMessage,
  withCurrentUser(),
  withCurrentEstate(),
  // withServiceTypes(),
  withFormik(formControl),
  withStyles(styles),
)(InnerForm);
