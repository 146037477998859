import { fetchDocumentCategoriesThunk } from "./documentOperations";

const { createSlice, createEntityAdapter } = require("@reduxjs/toolkit");

const entityAdapter = createEntityAdapter({
  selectId: (item) => item.id,
});

const slice = createSlice({
  name: "documentCategories",
  initialState: {
    isLoading: false,
    ...entityAdapter.getInitialState(),
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDocumentCategoriesThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchDocumentCategoriesThunk.fulfilled, (state, action) => {
      entityAdapter.setAll(state, action.payload);
      state.isLoading = false;
    });
    builder.addCase(fetchDocumentCategoriesThunk.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const documentCategorySelectors = entityAdapter.getSelectors(
  (state) => state.documents.documentCategories
);

export const reducer = slice.reducer;
