import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Toolbar, Divider } from "@material-ui/core";
import EstateSelect from "../../components/EstateSelect";
import { compose } from "recompose";
import TicketFilterMenu from "./TicketFilterMenu";

const styles = {
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  grow: {
    flexGrow: 1,
  },
};

function TicketListToolBar(props) {
  const { classes } = props;

  return (
    <Toolbar className={classes.toolbar}>
      <div className={classes.grow}>
        <EstateSelect />
      </div>
      {!props.noTicketFilter && (
        <React.Fragment>
          <Divider orientation="vertical" flexItem />
          <TicketFilterMenu />
        </React.Fragment>
      )}
    </Toolbar>
  );
}

TicketListToolBar.defaultProps = {
  noTicketFilter: PropTypes.boolean,
};

export default compose(withStyles(styles))(TicketListToolBar);
