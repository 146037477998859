import React from "react";
import PropTypes from "prop-types";
import { defineMessages, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withFormik } from "formik";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import * as Yup from "yup";
import { completeInvitation } from "../../modules/session/sessionActions";
import { route as homeRoute } from "./../HomeContainer/HomeContainer";
import { HTTP_INTERNAL_SERVER_ERROR } from "../../apiClient/apiClient";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

const messages = defineMessages({
  headline: {
    id: "AcceptInvite.completeInvite",
    defaultMessage: "Einladung abschließen",
  },
  subheadline: {
    id: "AcceptInvite.infotext",
    defaultMessage:
      "Nach der Passwortvergabe bitte ausloggen. Ihre Emailadresse ist gleichzeitig Ihr Benutzername.",
  },
  sendPassword: {
    id: "AcceptInvite.sendPassword",
    defaultMessage: "Passwort senden",
  },
  inputLabel: {
    id: "AcceptInvite.passwordTextLabel",
    defaultMessage: "Passwort",
  },
  submitError: {
    id: "AcceptInvite.submitError",
    defaultMessage: "Es ist etwas schiefgegangen. Versuche es später erneut",
  },
});

const styles = (theme) => ({
  layout: {
    paddingTop: theme.spacing(8),
    width: "auto",
    display: "block", // Fix IE11 ticket.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3,
    )}px`,
  },
  avatar: {
    margin: theme.spacing(),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE11 ticket.
    marginTop: theme.spacing(),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  error: {
    color: theme.palette.error,
  },
});

const passwordValidationScheme = Yup.object().shape({
  password: Yup.string().required(),
});

// Wrap our form with the using withFormik HoC
const formControl = {
  // Transform outer props into form values
  mapPropsToValues: (props) => ({
    password: "",
  }),
  // Add a custom validation function (this can be async too!)
  validationSchema: passwordValidationScheme,
  // Submission handler
  handleSubmit: async (
    values,
    { props, setSubmitting, setErrors, resetForm },
  ) => {
    const { password } = values;
    const { token } = props.match.params;
    const { formatMessage } = props.intl;

    props
      .dispatch(completeInvitation(token, password))
      .then((res) => {
        resetForm();
        setSubmitting(false);
        props.history.push(homeRoute);
      })
      .catch((err) => {
        let submitErrorMessage = {};
        if (err.code === HTTP_INTERNAL_SERVER_ERROR) {
          submitErrorMessage = formatMessage(messages.submitError);
          setErrors({ submitErrorMessage });
        }
      });
  },
};

const AcceptInviteForm = (props) => {
  const {
    classes,
    values,
    handleChange,
    handleSubmit,
    touched,
    errors,
    intl: { formatMessage },
  } = props;
  return (
    <React.Fragment>
      <main className={classes.layout}>
        s
        <Paper className={classes.paper}>
          <Typography variant="h5">
            {formatMessage(messages.headline)}
          </Typography>
          <Typography variant="subheading" align="center">
            {formatMessage(messages.subheadline)}
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Passwort</InputLabel>
              <Input
                type="password"
                error={touched.password && Boolean(errors.password)}
                id="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                autoComplete="password"
                autoFocus
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="raised"
              color="primary"
              className={classes.submit}
            >
              {formatMessage(messages.sendPassword)}
            </Button>
            {errors.submitError && (
              <Typography variant="subtitle1">
                {formatMessage(messages.submitError)}
              </Typography>
            )}
          </form>
        </Paper>
      </main>
    </React.Fragment>
  );
};

AcceptInviteForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  connect(),
  injectIntl,
  withStyles(styles),
  withRouter,
  withFormik(formControl),
)(AcceptInviteForm);
