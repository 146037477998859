import React from "react";

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import FilterList from "@material-ui/icons/FilterList";
import IconButton from "@material-ui/core/IconButton";
import ProcessIndicator from "../../components/ProcessIndicator/ProcessIndicator";
import { compose } from "recompose";
import { connect } from "react-redux";
import { processingStateTypes } from "./../../functions";
import { injectIntl } from "react-intl";
import { setTicketListFilterId } from "../../modules/ticket/ticketListFilter/ticketListActions";

class TicketFilterMenu extends React.Component {
  state = {
    auth: true,
    anchorEl: null,
  };

  handleChange = (event) => {
    this.setState({ auth: event.target.checked });
  };

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  applyFilter = (processingStateId) => {
    this.props.dispatch(setTicketListFilterId(processingStateId));
  };

  render() {
    const {
      intl: { formatMessage },
    } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div>
        <IconButton
          aria-owns={open ? "menu-appbar" : null}
          aria-haspopup="true"
          onClick={this.handleMenu}
          color="inherit"
        >
          <FilterList />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={this.handleClose}
        >
          <MenuItem
            style={{ justifyContent: "flex-end" }}
            onClick={(e) => {
              this.applyFilter("ALL");
              this.handleClose(e);
            }}
          >
            {formatMessage({ id: "ticket.filter.option.no_filter" })}
          </MenuItem>
          {processingStateTypes.map((type) => (
            <MenuItem
              style={{ justifyContent: "flex-end" }}
              key={type.id}
              onClick={(e) => {
                this.applyFilter(type.id);
                this.handleClose(e);
              }}
            >
              <ProcessIndicator status={type.id} />
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}

export default compose(connect(), injectIntl)(TicketFilterMenu);
