import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient } from "../../../apiClient/apiClient";

export const fetchPostingByIdThunk = createAsyncThunk(
  "posting/postingDetail/fetch",
  async (id) => {
    const response = await apiClient().get(`/communications/postings/${id}`);
    return response.data;
  }
);
