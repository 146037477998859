import React from "react";
import { List } from "@material-ui/core";
import { AttachmentListItem } from "./AttachmentListItem";

export default function AttachmentList({ attachments }) {
  return (
    <List>
      {attachments &&
        attachments.map((attachment) => (
          <AttachmentListItem key={attachment.id} item={attachment} />
        ))}
    </List>
  );
}
