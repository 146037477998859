import React from "react";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";

export { default as BackButton } from "./BackButton";
export { default as FolderList } from "./FolderList";
export { default as EstateToolbar } from "./EstateToolbar";
export { default as TabsContainer } from "./TabsContainer";
export { default as Images } from "./Images";
export { default as Loader } from "./Loader";
export { default as IOSwitch } from "./IOSCheckbox";
export { default as ContentEditor } from "./TextEditor";

export { default as StepsList } from "./List";

const styles = (theme) => ({
  text: {
    marginLeft: "1rem",
    marginRight: "1rem",
    color: "rgba(0, 0, 0, 0.54)",
    paddingBottom: "12px",
    fontSize: "0.875rem",
  },
});

/**
 * Dashboard component. Container for tickets related to a Liegenschaft
 */
class ViewMetaTitle extends React.PureComponent {
  render() {
    const props = this.props;

    return (
      <Typography
        className={classNames(props.classes.text, this.props.className)}
        variant="body1"
      >
        {props.title}
      </Typography>
    );
  }
}

export const ViewTitle = compose(withStyles(styles))(ViewMetaTitle);
