import * as actions from "./propertyManagementActions";

export const reducer = (propertyManagement = {}, action) => {
  switch (action.type) {
    case actions.SET_CURRENT:
      return action.payload.propertyManagement;

    default:
      return propertyManagement;
  }
};
