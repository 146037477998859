import { hasEntries } from "./array";
import { processingStateTypes } from "./constants";
import { lifecycle } from "recompose";
import { apiClient } from "../apiClient/apiClient";

export const getStateByID = (stateID) =>
  processingStateTypes.find((item) => item.id === stateID);

/**
 *
 * @param {*} ticket
 */
export const ticketsFactory = (ticket = {}) => {
  const _isPublic = (currentUser) => {
    const userTypes = ticket.accessibleByTypes || [];

    return Boolean(Number(userTypes.length));
  };

  // accessibleByPersonIds
  const _isPrivate = (currentUser = {}) => {
    // make sure all Numbers are actually numbers
    const newNumbersArray = ticket.accessibleByPersonIds
      ? ticket.accessibleByPersonIds.map(Number)
      : [];

    return Boolean(newNumbersArray.includes(Number(currentUser.id)));
  };

  /**
   * Returns true or false in case attachments are available or not
   */
  const _hasAttachments = (attachments) =>
      attachments ? hasEntries(attachments) : hasEntries(ticket.attachments),
    /**
     * Return attachments
     */
    _getAttachments = () => ticket.attachments || [],
    /**
     * Returns
     * @param {*} field
     */
    _getProperty = (field) => ticket[field],
    /**
     *
     */
    _hasType = (field) => Boolean(ticket.hasOwnProperty(field)),
    /**
     *
     */
    _getServiceType = (field) => ticket.serviceType[field], // e.g. item.serviceType.name
    /**
     *
     */
    _getMessages = () => (_hasType("messages") ? ticket.messages : []),
    /**
     *
     */
    _mapMessages = (callback) => _getMessages().map(callback);

  return {
    // common methods
    hasProperty: _hasType,
    getProperty: _getProperty,
    // specific fields on tickets
    isPublic: _isPublic,
    isPrivate: _isPrivate,
    //
    getServiceType: _getServiceType,
    // I need my attachments
    hasAttachments: _hasAttachments,
    getAttachments: _getAttachments,
    // I need more messages
    mapMessages: _mapMessages,
  };
};

export const READ_TICKETS_KEY = "readTickets";

export const getReadTicketListFromPersistance = () =>
  sessionStorage.getItem(READ_TICKETS_KEY);

export const setReadTicketListToPersistance = (ticketIds) =>
  sessionStorage.setItem(READ_TICKETS_KEY, ticketIds);

/**
 * Mapper for props to render either static text or text editor
 * NOTE: Make sure you have a currentUser imported. Otherwise,
 * the won't work.
 *
 * @param {*} props
 */
export const mapPrivateOrPublicMessages = (props) => {
  const {
    isPrivate,
    isPublic,
    // messagesEnabled
  } = ticketsFactory(props.item);
  // console.log("#######################");
  // console.log(props.item);
  // console.log(props.currentUser)
  // console.log("isPublic ", isPublic());
  // console.log("isPrivate ", isPublic());
  // console.log("#######################");
  return {
    ...props,
    // messagesEnabled: messagesEnabled,
    isPrivate: isPrivate(props.currentUser),
    isPublic: isPublic(),
  };
};

/**
 * Fetch data by domain, pass it to state data
 * and down to the wrapped component.
 * TODO: Lifecycle hook doesn't require a fetch anymore, hence, "state" isn't useful anymore.
 */
export const withEstateName = lifecycle({
  //
  state: {
    estateName: "",
  },

  async componentDidMount() {
    const { item } = this.props;
    if (item && item.estateId) {
      const { data } = await apiClient().get(`/estates/${item.estateId}`);
      this.setState({ estateName: data.name });
    }
  },
});
