import React, { useState, useRef, useCallback, useEffect } from "react";
import { compose } from "recompose";

import { usePdf } from "@mikecousins/react-pdf";
import { Typography, Button, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { apiClient } from "../../apiClient/apiClient";

import screenfull from "screenfull";
import Controls from "./Controls";

const styles = (theme) => {
  return {
    wrapper: {
      backgroundColor: theme.palette.grey[300],
    },
    mobile: {
      overflow: "auto",
      margin: "24px 0",
    },
    previewContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },

    canvasContainer: {
      padding: "24px",
    },

    positionTop: {
      alignItems: "flex-start",
    },
  };
};

const PdfViewer = ({ fileName, onClose, classes, theme }) => {
  const desktop = false;

  const canvasRef = useRef(null);
  const previewRef = useRef(null);

  const [page, setPage] = useState(1);
  const [currentSigUrl, setCurrentSigUrl] = useState(fileName);
  const [scale, setScale] = useState(desktop ? 1 : 0.5);
  const [fullscreen, setFullscreen] = useState(false);
  const [error, setError] = useState(false);

  const canvasEl = document.getElementById("canvas");
  const wrapperEl = document.getElementById("wrapper");

  const [canvasDimensions, setCanvasDimensions] = useState({
    height: canvasEl ? canvasEl.clientHeight : 0,
    width: canvasEl ? canvasEl.clientWidth : 0,
  });
  const [wrapperDimensions, setWrapperDimensions] = useState({
    height: wrapperEl ? wrapperEl.clientHeight : 0,
    width: wrapperEl ? wrapperEl.clientWidth : 0,
  });

  // ensures, that correct state is set, when view ist not in fullscreen mode
  // useEffect(() => {
  //     const screenfullChangeHandler = () => {
  //         if (!(screenfull).isFullscreen && fullscreen) {
  //             setFullscreen(false);
  //         }
  //     };
  //     (screenfull).onchange(screenfullChangeHandler);
  //     return () => {
  //         (screenfull).off("change", screenfullChangeHandler);
  //     };
  // }, [fullscreen]);

  useEffect(() => {
    const handleUrlWithSig = async () => {
      if (
        fileName &&
        fileName.includes("/attachments/") &&
        fileName.toLowerCase().endsWith(".pdf")
      ) {
        const url = fileName.replace(
          "/attachments/",
          "/attachments/_signature/"
        );

        const signatureResponse = await apiClient().get(url, {});
        // console.log("signatureObject", signatureResponse);

        const { data } = signatureResponse;
        // console.log("data", data);

        const { signature } = data;

        const signaturedUrl = `${fileName}?sig=${signature}`;
        // console.log("signaturedUrl", signaturedUrl);

        setCurrentSigUrl(signaturedUrl);
      } else {
        setCurrentSigUrl(fileName);
      }
    };

    handleUrlWithSig();

    const interval = setInterval(() => {
      console.log("get new sig last: ", currentSigUrl);
      handleUrlWithSig();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const onDocumentLoadFail = useCallback(() => {
    setError(true);
  }, []);

  const onToggleFullscreen = useCallback(() => {
    if (!previewRef.current) {
      return null;
    } else if (fullscreen) {
      setScale(1);
      setFullscreen(false);
      screenfull.exit();
    } else {
      setFullscreen(true);
      setScale(1);
      screenfull.request(previewRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullscreen]);

  const onPageRenderSuccess = () => {
    if (canvasEl && wrapperEl) {
      setCanvasDimensions({
        height: canvasEl.clientHeight,
        width: canvasEl.clientWidth,
      });
      setWrapperDimensions({
        height: wrapperEl.clientHeight,
        width: wrapperEl.clientWidth,
      });
    }
  };

  const { pdfDocument } = usePdf({
    file: fileName,
    page,
    canvasRef,
    scale,
    onDocumentLoadFail: onDocumentLoadFail,
    workerSrc: "/pdf.worker.min.js",
    onPageRenderSuccess: onPageRenderSuccess,
  });

  const downloadFile = useCallback(() => {
    window.open(currentSigUrl, "_blank");
  }, [fileName, currentSigUrl]);

  const onSetPage = useCallback((value) => {
    setPage(value);
  }, []);

  const onScale = useCallback((value) => {
    setScale(value);
  }, []);

  const DocumentLoadFailedMessage = () => {
    return (
      <div style={{ padding: 3 }}>
        <Typography variant="body1" gutterBottom>
          Es ist ein Fehler aufgetreten
        </Typography>
        <Button variant="contained" color="primary" onClick={onReload}>
          Neu Laden
        </Button>
      </div>
    );
  };

  const onReload = useCallback(() => window.location.reload(), []);

  useEffect(() => {
    onPageRenderSuccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scale, fullscreen]);

  const classNames = [classes.previewContainer];

  if (fullscreen) {
    classNames.push(classes.previewFullscreen);

    if (canvasDimensions.height + 90 >= window.innerHeight) {
      classNames.push(classes.positionTop);
    }
    if (canvasDimensions.width + 90 >= window.innerWidth) {
      classNames.push(classes.positionLeft);
    }
  } else {
    if (canvasDimensions.height + 48 >= wrapperDimensions.height) {
      classNames.push(classes.positionTop);
    }
    if (canvasDimensions.width + 48 >= wrapperDimensions.width) {
      classNames.push(classes.positionLeft);
    }
  }

  return (
    <div ref={previewRef} className={classes.wrapper} id="wrapper">
      {error && !desktop && <DocumentLoadFailedMessage />}
      {Boolean(pdfDocument && pdfDocument.numPages) && (
        <Controls
          numPages={pdfDocument.numPages}
          downloadFile={downloadFile}
          onToggleFullScreen={onToggleFullscreen}
          page={page}
          scale={scale}
          onScale={onScale}
          onSetPage={onSetPage}
          onClose={onClose}
          fullscreen={fullscreen}
          currentSigUrl={currentSigUrl}
        >
          <div className={classes.mobile}>
            {!error && !pdfDocument && <CircularProgress />}
            <div>
              <canvas ref={canvasRef} />
            </div>
          </div>
        </Controls>
      )}
      {/* {desktop && (
                <div className={classNames.join(" ")}>
                    {error && <DocumentLoadFailedMessage />}
                    {!error && !pdfDocument && <CircularProgress />}
                    <div className={classes.canvasContainer}>
                        <canvas id="canvas" ref={canvasRef} />
                    </div>
                </div>
            )} */}
    </div>
  );
};

export default compose(withStyles(styles, { withTheme: true }))(PdfViewer);
