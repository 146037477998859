import React from "react";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import { compose, mapProps } from "recompose";
import { CardHeader, Avatar, Divider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";

import { MessageListItem } from "./MessageListItem";
import { getImageSRC } from "../../functions/assets";

import {
  getLanguage,
  getTimeFromNow,
  ticketsFactory,
  mapPrivateOrPublicMessages,
  nonOptimalStates,
  sanitizeAllowed,
  withCurrentUser,
  withEstateName,
} from "../../functions";
import { getProcessIconByProcessingState } from "../ProcessIndicator/getProcessIconByProcessingState";
import { fetchAssigneesByEstateThunk } from "../../modules/ticket/ticketDetail/assigneeList/assigneeListOperations";
import { assigneeListSelectors } from "../../modules/ticket/ticketDetail/assigneeList/assigneeListSlice";
import TicketAssignDialogButton from "./components/TicketAssignDialogButton";
import { ROLES } from "../../modules/accounts/accountConstants";
import { accountSelectors } from "../../modules/accounts/accountSlice";
import { accountHasRole } from "../../modules/accounts/accountFunctions";
import { messageListSelectors } from "../../modules/ticket/ticketDetail/messages/messageListSlice";
import AttachmentList from "../AttachmentList/AttachmentList";
import { Loader } from "../Common";

const CreatedAt = (props) => (
  <Typography component="p" color="textSecondary">
    {props.item.updatedAt || props.item.createdAt}
  </Typography>
);

const Footer = (props) => (
  <Typography component="p" className={props.classes.serviceType}>
    <strong>{props.item.serviceType}</strong>
  </Typography>
);

const MessageFieldStatic = (props) => (
  <div
    className={props.classes.body}
    dangerouslySetInnerHTML={{ __html: sanitizeAllowed(props.document.body) }}
  />
);

const renderDescription = (item, classes) => {
  const descriptionArray = item.body ? item.body.split(/\n/) : [];
  const descriptionRows = descriptionArray.map((row, index) => {
    return (
      <div key={index} style={{ minHeight: "19px" }}>
        {row.split()}
      </div>
    );
  });

  return <div className={classes.body}>{descriptionRows}</div>;
};

/**
 *
 * @param {*} attachments
 */
const getFirstImage = (attachments) => {
  if (attachments && attachments[0]) {
    const file = attachments[0];
    return {
      preview: `${getImageSRC(file.previewFilename)}&scalePercent=100`,
      src: getImageSRC(file.filename),
    };
  }
};

/**
 *
 */
class MessageDetailItemPublic extends React.PureComponent {
  getCardOptions = (attachments) => {
    const { item } = this.props;
    // choose first image for preview
    const firstImage = getFirstImage(attachments);

    return {
      title: item.subject,
      // check whether there is a preview and src
      ...(firstImage && { ...firstImage }),
      // check for anonymous flag
      subheader: item.anonymous
        ? `${getTimeFromNow(item.createdAt, getLanguage())}`
        : `${item.author} ${getTimeFromNow(item.createdAt, getLanguage())}`,
    };
  };

  componentDidMount() {
    const { fetchAssignees, item } = this.props;
    if (item && item.estateId) {
      fetchAssignees(item.estateId);
    }
  }

  render() {
    const { classes, item, messages, assignees, userIsTicketManager } =
      this.props;

    const {
      // hasAttachments,
      getServiceType,
      hasProperty,
      getAttachments,
    } = ticketsFactory(item);

    const cardHeaderOptions = this.getCardOptions(getAttachments());

    const ticketManagers = assignees.filter((assignee) =>
      assignee.relations.includes(ROLES.TICKET_MANAGER),
    );

    return (
      <Card className={classes.root}>
        <CardHeader
          title={cardHeaderOptions.title}
          subheader={cardHeaderOptions.subheader}
          action={
            <div style={{ display: "flex" }}>
              {ticketManagers.map((manager) => (
                <Avatar key={manager.id}>
                  {manager.initials.toUpperCase()}
                </Avatar>
              ))}
              {/* add a small overlap to the previous avatar */}
              <Avatar
                style={{
                  backgroundColor: "whitesmoke",
                  marginLeft: "-0.25rem",
                }}
              >
                {getProcessIconByProcessingState(item.processingState)}
              </Avatar>
            </div>
          }
        />

        {item.attachments && (
          <React.Fragment>
            <Divider />
            <AttachmentList attachments={item.attachments} />
          </React.Fragment>
        )}

        <Divider />
        <CardContent className={classes.cardContent}>
          <div className={classes.footer}>
            {hasProperty("serviceType") && (
              <Footer
                classes={classes}
                item={{ serviceType: getServiceType("name") }}
              />
            )}
          </div>
        </CardContent>

        <CardContent className={classes.cardContent}>
          {renderDescription(item, classes)}
        </CardContent>

        {userIsTicketManager && (
          <CardContent>
            <TicketAssignDialogButton ticket={item} />
          </CardContent>
        )}

        <Divider />

        <CardContent>
          {messages &&
            messages.map((message) => (
              <MessageListItem key={message.id} document={message} />
            ))}
        </CardContent>
      </Card>
    );
  }
}

/**
 * You get this component only if this message is private.
 * TODO: This is not necessary anymore, however, I am going
 * leave it active and check back later. Maybe there is an
 * advantage to keep private and public seperate?
 */
class MessageDetailItemPrivate extends React.PureComponent {
  getCardProperties = (attachments) => {
    const { item } = this.props;
    // choose first image for preview
    const firstImage = getFirstImage(attachments);

    return {
      title: item.subject,
      // check whether there is a preview and src
      ...(firstImage && { ...firstImage }),
      // check for anonymous flag
      subheader: item.anonymous
        ? `${getTimeFromNow(item.createdAt, getLanguage())}`
        : `${item.author} ${getTimeFromNow(item.createdAt, getLanguage())}`,
    };
  };

  render() {
    const { classes, item, messages, messagesIsLoading } = this.props;

    const { getAttachments } = ticketsFactory(item);

    const cardHeaderOptions = this.getCardProperties(getAttachments());

    return (
      <Card className={classes.root}>
        <CardHeader
          title={cardHeaderOptions.title}
          subheader={cardHeaderOptions.subheader}
        />

        {item.attachments && (
          <React.Fragment>
            <Divider />
            <AttachmentList attachments={item.attachments} />
            <Divider />
          </React.Fragment>
        )}

        <CardContent className={classes.cardContent}>
          <MessageFieldStatic document={item} classes={classes} />
        </CardContent>
        <hr />

        <CardContent className={classes.cardContent}>
          <div className={classes.footer}>
            <CreatedAt
              classes={classes}
              item={{
                createdAt: getTimeFromNow(item.createdAt, getLanguage()),
                updatedAt: getTimeFromNow(item.updatedAt, getLanguage()),
              }}
            />
          </div>
        </CardContent>

        {/* Chat messages */}
        {messagesIsLoading && <Loader />}
        {messages && messages.length > 0 && (
          <CardContent>
            {messages.map((document) => (
              <MessageListItem key={document.id} document={document} />
            ))}
          </CardContent>
        )}
      </Card>
    );
  }
}

/**
 * Checks wether a message is private or public
 * @param {*} param0
 */
const ticketIsPrivate = (props) => {
  const hasTypeAccess = !Boolean(
    props.item &&
      props.item.accessibleByTypes &&
      props.item.accessibleByTypes.length,
  );
  return hasTypeAccess;
};

/**
 *
 */
const MessageDetailItemContainer = nonOptimalStates([
  {
    when: ticketIsPrivate,
    render: MessageDetailItemPrivate,
  },
])(MessageDetailItemPublic);

/**
 *
 * @param {*} theme
 */
const styles = (theme) => ({
  root: {
    margin: 16,
  },

  cardHeaderRoot: {
    paddingBottom: "0px",
  },

  media: {
    height: 0,
    // paddingTop: '56.25%', // 16:9
  },

  cardContent: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(),
  },

  head: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: ".5rem",
  },

  title: {
    fontSize: 18,
  },

  state: {
    fontSize: 14,
    textAlign: "right",
    textTransform: "uppercase",
  },

  flex: {
    display: "flex",
    alignItems: "center",
  },

  pos: {
    paddingBottom: 16,
  },

  section: {
    margin: "16px 0",
  },

  serviceType: { fontWeight: 700 },

  footer: {
    display: "flex",
    justifyContent: "space-between",
  },

  body: theme.typography.body1,
});

const mapStateToProps = (state, ownProps) => {
  const currentAccount = accountSelectors.selectCurrentAccount(state);
  const userIsTicketManager = accountHasRole(
    currentAccount,
    ROLES.TICKET_MANAGER,
  );

  const messages = messageListSelectors.selectAll(state);
  const messagesIsLoading = messageListSelectors.selectLoadingState(state);

  return {
    messages,
    messagesIsLoading,
    assignees: assigneeListSelectors.selectByIds(
      state,
      ownProps.item.assigneeIds,
    ),
    userIsTicketManager,
  };
};

const mapDispatchToProps = {
  fetchAssignees: fetchAssigneesByEstateThunk,
};

/**
 * Add currentUser, styles and whether the tickets are private or public
 * TODO: This composer should be taken apart. Start with private and public.
 *
 * !!!Decoration overload!!!
 */
export default compose(
  withCurrentUser(),
  withEstateName,
  connect(mapStateToProps, mapDispatchToProps),
  mapProps(mapPrivateOrPublicMessages),
  withStyles(styles, { withTheme: true }),
)(MessageDetailItemContainer);
