import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
});

const CircularIndeterminate = (props) => (
  <CircularProgress className={props.classes.progress} />
);

CircularIndeterminate.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CircularIndeterminate);
