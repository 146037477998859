import React from "react";
import List from "../../components/Common/List";

/**
 * Default List item
 */
class FolderList extends React.PureComponent {
  render() {
    const { classes, data } = this.props;

    return (
      <List
        subheader={this.props.subheader}
        component="nav"
        className={classes && classes.root}
        disablePadding={this.props.disablePadding}
      >
        {data.map(this.props.children)}
      </List>
    );
  }
}

export default FolderList;
