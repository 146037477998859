import { apiClient } from "../../apiClient/apiClient";
import { setCurrentEstateById, setEstatesByIds } from "../estate/estateActions";
import { getSessionToken } from "../session/functions";
import { clearState } from "../../localStorage";
import store, { history } from "../../store";
import { setNotificationMessage } from "../uiState";
import SignInContainer from "../../containers/SignInContainer/SignInContainer";
import { hasEntries } from "../../functions";
import { accountActions } from "../accounts/accountSlice";

// actions
export const SET_CURRENT_USER = "user/SET_CURRENT_USER";

// action creators
const setCurrentUser = (user) => ({ type: SET_CURRENT_USER, payload: user });

// operations
export const fetchCurrentUserThunk = () => {
  return async (dispatch) => {
    const token = getSessionToken();

    try {
      const sessionResponse = await apiClient().get(`sessions/${token}`);
      if (sessionResponse.status === 200) {
        const { data: sessionData } = sessionResponse;
        const accountId = sessionData.user.accountId;
        dispatch(accountActions.setData(sessionData.user));

        // get user data
        const accountResponse = await apiClient().get(`accounts/${accountId}`);

        if (accountResponse.status === 200) {
          const { person } = accountResponse.data;
          const personResponse = await apiClient().get(
            `persons/${accountResponse.data.person.id}`,
          );

          const { data } = personResponse;

          // ! hack, normally we should get all information from person endpoint, but this is currently not the case
          // ! relations property is missing in person response
          // ! service type ids is missing in account response
          // ! I need to merge booth data to get the needed data
          const userData = {
            ...data,
            ...person,
          };

          if (userData != null && !userData.hasOwnProperty("relations")) {
            // You cannot use the app without additional user data.
            dispatch(
              setNotificationMessage(
                "Missing user relation in account object. Please call your Admin.",
              ),
            );
            console.warn("Missing relation in user object.", userData);
            history.push(SignInContainer.route);
            return;
          }

          if (userData != null && hasEntries(userData.relations)) {
            dispatch(setCurrentUser(userData));

            // set all optional estates for the current user
            const estateIds = userData.relations.map(
              (relation) => relation.estateId,
            );
            // console.log(estateIds);
            dispatch(setEstatesByIds([...new Set(estateIds)]));

            try {
              const { currentEstate } = store.getState().estates;
              // if an estate is already set, this should be skipped
              if (Object.keys(currentEstate).length === 0) {
                dispatch(setCurrentEstateById(estateIds[0]));
              }
            } catch (err) {
              console.error(
                "Error occurs while getting current estate state.",
                err,
              );
            }
          }
        }
      }
    } catch (err) {
      console.error("Problems while fetching account or session data.", err);
    }
  };
};

const userLogoutAction = () => ({ type: "user/LOGOUT" });

export const logoutUser = () => {
  return async (dispatch) => {
    try {
      const response = await apiClient().delete("/sessions/_self");
      if (response.status === 200) {
        setNotificationMessage("Logged out successfully.");
      } else {
        setNotificationMessage("Error logging out, please contact your admin.");
        console.warn("Error logging out, please contact your admin.", response);
      }
      return true;
    } catch (err) {
      console.error("Problems while fetching account or session data.", err);
      return false;
    } finally {
      dispatch(userLogoutAction());
      clearState();
    }
  };
};

// reducer

export const reducer = (user = {}, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return action.payload;

    default:
      return user;
  }
};
