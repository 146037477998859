import { connectRouter, routerMiddleware } from "connected-react-router";
import rootReducer from "./modules";
import { loadState } from "./localStorage";
import { createBrowserHistory } from "history";
import { configureStore } from "@reduxjs/toolkit";

export const history = createBrowserHistory();

const persistedState = loadState();

const store = configureStore({
  reducer: connectRouter(history)(rootReducer),
  preloadedState: persistedState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(routerMiddleware(history)),
});

export default store;
