import React, { Component } from "react";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getScreenDimensions, DEFAULT_APP_WIDTH } from "../../functions";
import { hausifyLogoTransparent } from "./../assets";
import TicketListToolBar from "../TicketListContainer/TicketListToolBar";
import AppBar from "./../../components/DefaultAppBar";
import MitteilungenContainer from "./MitteilungenContainer";
import PostingContainer from "../PostingContainer/PostingContainer";
import { setTicketRead } from "../../modules/ticket/ticketsRead/ticketsReadActions";
import { TicketCreateButton } from "../TicketListContainer/TicketCreateButton";
import { injectIntl } from "react-intl";
import { getImageSRC } from "../../functions/assets";

export const route = "/";

/**
 *
 * @param {*} theme
 */
const styles = (theme) => {
  const { x } = getScreenDimensions();
  return {
    root: {
      position: "relative",
      paddingTop: "4rem", // gap for fixed tab bar
      backgroundColor: theme.palette.background.whitesmoke,
      marginBottom: "4rem",
    },
    list: {
      paddingTop: "1rem", // gap for fixed tab bar
    },
    tabs: {
      backgroundColor: theme.palette.background.paper,
      width: "100%",
    },
    listItem: {
      backgroundColor: theme.palette.background.default,
      borderTop: "#ccc solid 1px",
      borderBottom: "#ccc solid 1px",
    },
    badge: {
      right: "-14px",
    },

    center: {
      display: "flex",
      margin: "50% auto",
      fontSize: "10rem",
    },
    img: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
    },
    logo: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "15px",
      marginBottom: "20px",
      width: x < DEFAULT_APP_WIDTH ? "75%" : "50%",
    },
  };
};

class Mitteilungen extends React.Component {
  handleOpenTicket = (id) => {
    this.props.setTicketRead(id);
    this.props.history.push(`/tickets/${id}`);
  };

  render() {
    return (
      <MitteilungenContainer
        {...this.props}
        onClick={this.handleOpenTicket}
        disablePadding
      />
    );
  }
}

/**
 *
 */
const MitteilungenOverview = compose(
  withRouter,
  connect(null, { setTicketRead }),
)(Mitteilungen);

class HomeContainer extends Component {
  static route = route;

  render() {
    const {
      classes,
      propertyManagement,
      intl: { formatMessage },
    } = this.props;

    const tickets = formatMessage({ id: "dashboard.tickets.title" });
    const postings = formatMessage({ id: "dashboard.postings.title" });

    const logoFallback = {
      filename: hausifyLogoTransparent,
      name: "Hausify Logo",
    };
    const logo =
      (propertyManagement && propertyManagement.logo) || logoFallback;

    return (
      <div className={classes.root}>
        <AppBar>
          <TicketListToolBar />
        </AppBar>

        <div className={classes.logo}>
          <img
            width="50%"
            className={classes.img}
            src={getImageSRC(logo.filename)}
            alt={logo.name}
            style={{ minHeight: "80px" }}
          />
        </div>

        <div style={{ marginBottom: "1rem" }}>
          {/* This containers responsibility is to show all available postings */}
          <PostingContainer title={postings.toUpperCase()} />
        </div>

        <div>
          <MitteilungenOverview dense title={tickets.toUpperCase()} />
        </div>

        {/* FAB button */}
        <TicketCreateButton />
      </div>
    );
  }
}

const mapStateToProps = ({ propertyManagement }) => ({ propertyManagement });

const setDispatchToProps = { setTicketRead };

export default compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps, setDispatchToProps),
  withStyles(styles, { withTheme: true }),
)(HomeContainer);
