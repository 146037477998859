import { route as homeRoute } from "../../containers/HomeContainer/HomeContainer";

// actions
export const SET_MAIN_NAVIGATION_OPTION =
  "navigation/SET_MAIN_NAVIGATION_OPTION";

// action creators
export const setMainNavigationOption = (option) => ({
  type: SET_MAIN_NAVIGATION_OPTION,
  payload: option,
});

// reducer
export const reducer = (state = homeRoute, action) => {
  switch (action.type) {
    // case SET_MAIN_NAVIGATION_OPTION:
    //   return action.payload;
    case "@@router/LOCATION_CHANGE":
      return action.payload.location.pathname;
    default:
      return state;
  }
};
