import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MuiList from "@material-ui/core/List";
import { Typography } from "@material-ui/core";

/**
 *
 * @param {*} theme
 */
const styles = (theme) => ({
  root: {
    width: "100%",
    position: "relative",
    overflow: "auto",
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
});

/**
 *
 * @param {*} props
 */
const List = (props) => {
  const { classes, ...rest } = props;

  return (
    <MuiList
      {...rest}
      subheader={
        props.subheader ? (
          <Typography variant="body1">{props.subheader}</Typography>
        ) : (
          <li />
        )
      }
    >
      {props.children}
    </MuiList>
  );
};

List.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(List);
