import React from "react";

import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";

import {
  RemoveCircleOutline as DeleteIcon,
  InsertDriveFile as FileIcon,
} from "@material-ui/icons";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { IOSwitch } from "./../../../components/Common";
import { compose } from "recompose";
import { injectIntl } from "react-intl";
import { FileInputButton } from "./FileInputButton";

/**
 *
 * @param {*} param0
 */
const ImageUploadSection = ({
  classes,
  handleSetFieldValue,
  intl: { formatMessage },
  ...formProps
}) => {
  const handleFileInputChange = (fileList) => {
    // manually set the list of attachments for formik
    handleSetFieldValue("capture", fileList);
  };

  const handleFileRemove = (id) => {
    const sanitizedFiles = formProps.values.capture.filter(
      (file) => !(id === file.id)
    );
    // manually set the list of attachments for formik
    handleSetFieldValue("capture", sanitizedFiles);
  };

  return (
    <React.Fragment>
      <section className={classes.section}>
        <header className={classes.header}>
          <Typography>{/* Foto hinzufügen (optional) */}</Typography>
        </header>
        <div className={classes.form}>
          {/* hide input to make it as native button element available */}

          <List dense={true}>
            {formProps.values.capture.map((file) => (
              <ListItem key={file.id}>
                <ListItemIcon>
                  <FileIcon />
                </ListItemIcon>
                <ListItemText>{file.name}</ListItemText>
                <ListItemSecondaryAction>
                  <IconButton onClick={() => handleFileRemove(file.id)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>

          <label htmlFor="capture">
            <FileInputButton onChange={handleFileInputChange} />
          </label>
        </div>
      </section>

      <div className={classes.form}>
        <section className={classes.section}>
          <div id="CaptureCanvas" className={classes.width100} />
        </section>
      </div>

      <div className={classes.switchContainer}>
        <div className={classes.switchLabes}>
          <Typography component="p">
            {formatMessage({ id: "ticket.create.private.switch.label" })}
          </Typography>
          <FormHelperText>
            {formatMessage({ id: "ticket.create.private.switch.form_helper" })}
          </FormHelperText>
        </div>
        <FormControl className={classes.form}>
          <IOSwitch
            checked={formProps.values.private}
            onChange={(event, value) => {
              handleSetFieldValue("private", value);
            }}
          />
        </FormControl>
      </div>
      <div className={classes.switchContainer}>
        <div className={classes.switchLabes}>
          <Typography component="p">
            {formatMessage({ id: "ticket.create.anonymous.switch.label" })}
          </Typography>
          <FormHelperText>
            {formatMessage({
              id: "ticket.create.anonymous.switch.form_helper",
            })}
          </FormHelperText>
        </div>
        <FormControl className={classes.form} style={{ marginBottom: "12px" }}>
          <IOSwitch
            onChange={(event, value) => {
              handleSetFieldValue("anonymous", value);
            }}
          />
        </FormControl>
      </div>
    </React.Fragment>
  );
};

export default compose(injectIntl)(ImageUploadSection);
