import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Fab from "@material-ui/core/Fab";
import CreateIcon from "@material-ui/icons/Create";
import { route as ticketFormRoute } from "../TicketFormContainer/TicketFormContainer";

export const styles = (theme) => ({
  root: {
    margin: theme.spacing(),
    position: "fixed",
    right: "2rem",
    bottom: "5rem",
  },
});

export const TicketCreateButton = withStyles(styles)(
  withRouter((props) => (
    <Fab
      onClick={() => props.history.push(ticketFormRoute)}
      color="secondary"
      aria-label="Add"
      className={props.classes.root}
    >
      <CreateIcon />
    </Fab>
  )),
);
