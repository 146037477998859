import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import PostingSingleImageList from "./PostingSingleImageList";
import tileDataDefault from "./postingTileDataDefault";

import { ViewTitle } from "../../components/Common";

const styles = (theme) => ({
  listItem: {
    lineHeight: "unset",
  },
});

class PostingContainer extends React.PureComponent {
  render() {
    const props = this.props;

    return (
      <React.Fragment>
        <ViewTitle title={props.title} />
        <PostingSingleImageList
          data={props.postings}
          mapper={this.postingsMapper}
          defaultData={this.props.defaultData}
        />
      </React.Fragment>
    );
  }
}

PostingContainer.defaultProps = {
  defaultData: tileDataDefault,
};

const mapStateToProps = ({ postings }) => ({
  postings: postings.postings,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(PostingContainer);
