import React from "react";
import { compose } from "recompose";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  IconButton,
  withStyles,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import { withShowNotificationMessage } from "../../../modules/uiState";
import { connect } from "react-redux";
import { accountSelectors } from "../../../modules/accounts/accountSlice";
import {
  taskAcceptThunk,
  taskCompleteThunk,
} from "../../../modules/ticket/ticketDetail/currentTicket/currentTicketOperations";
import { assigneeListSelectors } from "../../../modules/ticket/ticketDetail/assigneeList/assigneeListSlice";
import { ROLES } from "../../../modules/accounts/accountConstants";

function TicketAssignDialogButton({
  showNotificationMessage,
  ticketIsAssignedToCurrentUser,
  dispatch,
  ticket,
  accountId,
  isAssignedToOtherTicketManager,
}) {
  // is the ticket assigned to me?
  // isAssigned: is the account id in assignee list
  // setAssigned: action assign to account id

  // is the dialog open?
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  const handleClose =
    (taskComplete = null) =>
    () => {
      if (taskComplete === true) {
        dispatch(
          taskCompleteThunk({ ticket, taskComplete, assigneeId: accountId }),
        )
          .then(() => {
            showNotificationMessage("Auftrag wurde abgeschlossen");
          })
          .catch((error) => {
            showNotificationMessage(error.message);
          });
      }
      if (taskComplete === false) {
        dispatch(
          taskCompleteThunk({ ticket, taskComplete, assigneeId: accountId }),
        )
          .then(() => {
            showNotificationMessage("Auftrag wurde abgebrochen");
          })
          .catch((error) => {
            showNotificationMessage(error.message);
          });
      }
      setOpen(false);
    };

  return (
    <React.Fragment>
      {!ticketIsAssignedToCurrentUser ? (
        <Button
          fullWidth
          variant="contained"
          color="primary"
          disabled={
            ticket.ticketManagerTaskIsLoading || isAssignedToOtherTicketManager
          }
          onClick={() => {
            // showNotificationMessage("Auftrag wurde angenommen.");
            dispatch(taskAcceptThunk({ ticket, assigneeId: accountId }));
          }}
        >
          {/* TODO: translate */}
          Aufgabe annehmen
        </Button>
      ) : (
        <React.Fragment>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleClickOpen}
            disabled={
              ticket.ticketManagerTaskIsLoading ||
              isAssignedToOtherTicketManager
            }
          >
            {/* TODO: translate */}
            Aufgabe beenden
          </Button>
          <Dialog
            open={open}
            // open={true}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitleCustom id="alert-dialog-title" onClose={handleClose()}>
              Aufgabe erledigt?
            </DialogTitleCustom>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Konnte die Aufgabe vollständig erledigt werden?
              </DialogContentText>
            </DialogContent>
            <DialogActions
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button onClick={handleClose(true)} color="primary">
                Ja
              </Button>
              <Button onClick={handleClose(false)} color="secondary" autoFocus>
                Nein
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      )}
      <Typography variant="caption" color="secondary" align="center">
        {isAssignedToOtherTicketManager && "(Aufgabe wurde bereits angenommen)"}
      </Typography>
    </React.Fragment>
  );
}

const DialogTitleCustom = withStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(),
    top: theme.spacing(2),
    // color: theme.palette.grey[500]
  },
}))((props) => {
  const { children, classes, onClose } = props;
  return (
    <DialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

export default compose(
  connect((state, ownProps) => {
    const accountId = accountSelectors.selectAccountId(state);

    const assigneeIds = ownProps.ticket.assigneeIds;

    const ticketIsAssignedToCurrentUser =
      accountId && assigneeIds && assigneeIds.includes(accountId);

    // get list of ticket assignees
    const assigneesWithoutCurrentUser = assigneeListSelectors.selectByIds(
      state,
      assigneeIds,
    );
    // find all ticket managers in ticket assignee list
    const otherTicketManagers = assigneesWithoutCurrentUser.find(
      (assignee) =>
        assignee.relations.includes(ROLES.TICKET_MANAGER) &&
        assignee.id !== accountId,
    );
    const isAssignedToOtherTicketManager = !!otherTicketManagers;

    return {
      ticketIsAssignedToCurrentUser,
      accountId,
      isAssignedToOtherTicketManager,
    };
  }),
  withShowNotificationMessage,
)(TicketAssignDialogButton);
