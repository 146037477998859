import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import DocumentFolderItem, { PanelComponent } from "./DocumentFolderItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { hasEntries } from "../../functions";
import { compose } from "recompose";
import { FolderList } from "../Common";

/**
 *
 * @param {*} theme
 */
const styles = (theme) => ({
  root: {
    width: "100%",
    borderTopLeftRadius: "0px",
    "&:first-child": {
      borderTopLeftRadius: "0px",
    },
  },
  heading: {
    // fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  column: {
    // flexBasis: "33.33%",
  },
});

/**
 *
 */
const ExpansionPanel = withStyles({
  root: {
    borderBottom: "0px solid rgba(0,0,0,.125)",
    borderTopLeftRadius: "0px",
    "&:and(:first-child)": {
      borderTopLeftRadius: "0px",
    },
    backgroundColor: "inherit",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  },
  expanded: {
    margin: "auto",
  },
})(MuiExpansionPanel);

ExpansionPanel.muiName = "ExpansionPanelHausify";

/**
 *
 */
const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "inherit",

    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

ExpansionPanelSummary.muiName = "ExpansionPanelSummary";

/**
 *
 */
const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: 0,
    display: "block",
  },
}))(MuiExpansionPanelDetails);

/**
 *
 * @param {*} props
 */
export const DocumentsListContainer = (props) => (
  <FolderList data={props.data}>
    {(document, index) => (
      <DocumentFolderItem
        key={index}
        document={document}
        component={props.component || PanelComponent}
      />
    )}
  </FolderList>
);

class CustomizedExpansionPanel extends React.Component {
  state = {
    expanded: 0,
  };

  handleChange = (panel) => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  render() {
    const { expanded } = this.state;
    const {
      classes,
      primary = [],
      secondary = [],
      getSecondaryContent,
    } = this.props;

    return (
      <div className={classes.root}>
        {primary
          ? primary.map((category, index) => {
              const secondaryContent = getSecondaryContent(
                secondary.filter((data) => data.category.id === category.id),
              );

              return (
                hasEntries(secondaryContent) && (
                  <ExpansionPanel
                    key={index}
                    disabled={category.disabled}
                    expanded={expanded === category.id}
                    onChange={this.handleChange(category.id)}
                  >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <div className={classes.column}>
                        <Typography variant="subtitle1">
                          {category.name}
                        </Typography>
                      </div>
                    </ExpansionPanelSummary>

                    {hasEntries(secondaryContent) && (
                      <ExpansionPanelDetails>
                        <DocumentsListContainer data={secondaryContent} />
                      </ExpansionPanelDetails>
                    )}
                  </ExpansionPanel>
                )
              );
            })
          : null}
      </div>
    );
  }
}

export default compose(withStyles(styles))(CustomizedExpansionPanel);
