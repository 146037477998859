import React, { Component } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import { connect } from "react-redux";
import { compose } from "recompose";

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

class NotificationComponent extends Component {
  render() {
    return (
      <Snackbar
        open={this.props.notification.open}
        TransitionComponent={TransitionUp}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={this.props.notification.message}
      />
    );
  }
}

export default compose(connect(({ uiState }) => ({ notification: uiState })))(
  NotificationComponent
);
