import React, { Component } from "react";
import LayoutContentCenteredWithBackground from "../../components/Layouts/ContentCenteredWithBackgroundLayout";
import SignInForm from "./SignInForm";

export const route = "/sign-in";

export default class SignInContainer extends Component {
  static route = route;
  render() {
    return (
      <LayoutContentCenteredWithBackground>
        <SignInForm />
      </LayoutContentCenteredWithBackground>
    );
  }
}
