import { connect } from "react-redux";

// actions
const OPEN_NOTIFICATION = "tickets/OPEN_NOTIFICATION";
const CLOSE_NOTIFICATION = "tickets/CLOSE_NOTIFICATION";

// action creators
export function setNotificationMessage(message) {
  return {
    type: OPEN_NOTIFICATION,
    payload: message,
  };
}
export function clearNotification() {
  return {
    type: CLOSE_NOTIFICATION,
  };
}

// operations
export const showNotificationMessage = (message) => {
  return async (dispatch) => {
    dispatch(setNotificationMessage(message));
    setTimeout(() => dispatch(clearNotification()), 2000);
  };
};

/**
 *
 */
export const withShowNotificationMessage = connect(null, {
  showNotificationMessage,
});

// reducer
export const reducer = (state = { message: "", open: false }, action) => {
  switch (action.type) {
    case CLOSE_NOTIFICATION:
      return { message: "", open: false };
    case OPEN_NOTIFICATION:
      return { message: action.payload, open: true };

    default:
      return state;
  }
};
