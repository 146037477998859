import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import throttle from "lodash.throttle";
import { compose } from "recompose";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { fetchDocumentsThunk } from "../../modules/documents/documentOperations";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
  },
  input: {
    padding: 0,
    marginLeft: 8,
    flex: 1,
  },
};

class SearchInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { q: "" };
    this.autocompleteSearchDebounced = throttle(this.autocompleteSearch, 900);
  }

  changeQuery = (event) => {
    this.setState({ q: event.target.value }, () => {
      this.autocompleteSearchDebounced(this.state.q);
    });
  };

  autocompleteSearch = (q) => {
    this._fetch(q);
  };

  _fetch = (q) => {
    this.props
      .fetchDocumentsThunk({
        params: {
          sort: `["createdAt","ASC"]`,
          filter: { fulltext: q },
        },
      })
      .then(() => {
        this.props.onChange({
          active: this.state.q && this.state.q !== "",
        });
      });
  };

  render() {
    const {
      classes,
      intl: { formatMessage },
    } = this.props;
    return (
      <Paper className={classes.root} elevation={0}>
        <IconButton aria-label="Search">
          <SearchIcon />
        </IconButton>
        <InputBase
          classes={classes}
          placeholder={formatMessage({ id: "search.input.placeholder" })}
          value={this.state.q}
          onChange={this.changeQuery}
        />
      </Paper>
    );
  }
}

SearchInput.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  fetchDocumentsThunk,
};

export default compose(
  connect(undefined, mapDispatchToProps),
  withStyles(styles),
  injectIntl
)(SearchInput);
