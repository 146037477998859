import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { fetchMessagesByTicketIdThunk } from "./messageListOperations";

const messageListAdapter = createEntityAdapter();

const slice = createSlice({
  name: "ticket-messages",
  initialState: messageListAdapter.getInitialState({ isLoading: false }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMessagesByTicketIdThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMessagesByTicketIdThunk.fulfilled, (state, action) => {
      messageListAdapter.setAll(state, action.payload);
      state.isLoading = false;
    });
  },
});

const selectLoadingState = (state) =>
  state.tickets.ticketDetail.messages.isLoading;

const selectors = messageListAdapter.getSelectors(
  (state) => state.tickets.ticketDetail.messages
);

export const messageListSelectors = { ...selectors, selectLoadingState };

export const reducer = slice.reducer;
