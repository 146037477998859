import { fetchDocumentsThunk } from "../documents/documentOperations";
import { setPropertyManagement } from "../propertyManagement/propertyManagementActions";
import { fetchPropertyManagementByIdAsync } from "../propertyManagement/propertyManagementApi";
import { fetchTicketsByEstateIdThunk } from "../ticket/ticketList/ticketsActions";
import { fetchPostingsByEstateId } from "./../postings/postingsActions";
import { fetchEstateByIdAsync } from "./estateApi";

// actions
export const SET_CURRENT_ESTATE = "estate/SET_CURRENT_ESTATE";
export const SET_ESTATES = "estate/SET_ESTATES";

// action creators
export const setCurrentEstate = (estate) => ({
  type: SET_CURRENT_ESTATE,
  payload: estate,
});

export const setEstates = (estates) => ({
  type: SET_ESTATES,
  payload: estates,
});

// operations
export const setCurrentEstateById = (estateId) => {
  return async (dispatch) => {
    try {
      const estateResponse = await fetchEstateByIdAsync(estateId);

      if (estateResponse.status === 200) {
        const { data: estate } = estateResponse;

        // set the current selected estate for operations
        dispatch(setCurrentEstate(estate));
        // update current tickets list
        dispatch(fetchTicketsByEstateIdThunk(estate.id));
        // update current postings list
        dispatch(fetchPostingsByEstateId(estate.id));
        // update documents list, implicitly by updating the current estate
        dispatch(fetchDocumentsThunk());

        // try to get the current property management, this is mandatory in any case
        try {
          if (estate.propertyManagementId) {
            const propertyManagementResponse =
              await fetchPropertyManagementByIdAsync(
                estate.propertyManagementId,
              );

            if (propertyManagementResponse.status === 200) {
              const { data: propertyManagement } = propertyManagementResponse;
              dispatch(setPropertyManagement(propertyManagement));
            }
          }
        } catch (err) {
          console.error(
            "Problems while fetching current property management in estate action.",
            err,
          );
        }
      }
    } catch (err) {
      console.error("Problems while fetching current estate.", err);
    }
  };
};

export const setEstatesByIds = (estateIds) => {
  return async (dispatch) => {
    // O-ton: Protofy
    // ein Rechtethema: Die Suchfunktion ist aktuell nur für Verwalter/Admins freigeschaltet, nicht aber für einfache Bewohner. Bei der Einzelabfrage auf Estates dagegen dürfen auch Bewohner diese lesen, wenn sie eine Relation zu diesen haben.
    // Es hätte hier zwei Wege gegeben:
    // Entweder: Erweiterung des Backends, so dass die Suchabfrage auch für Bewohner funktioniert jedoch nur die Estates zurückliefert, die auch gesehen werden können.
    // Oder: Umstellung der Mehrfachabfrage auf mehrere /gets mit der EstateId. Letztere war aufwandsärmer und für den eher seltenen Use-Case Bewohner hat mehr als eine Wohnung aus unserer Sicht angemessen.
    try {
      const promises = estateIds.map((id) => fetchEstateByIdAsync(id));
      const estates = (await Promise.all(promises)).map(
        (response) => response.data,
      );

      dispatch(setEstates(estates));
      // set the first estate as current estate
      dispatch(setCurrentEstateById(estates[0].id));
    } catch (err) {
      console.error("Problems while fetching estates.", err.message);
    }
  };
};
