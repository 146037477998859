import React from "react";
import ContentEditable from "react-contenteditable";
// import { withStyles } from "@material-ui/core";

class ContentEditor extends React.PureComponent {
  state = {
    editable: true,
  };

  // sanitizeConf = {
  //   allowedTags: ["b", "i", "em", "strong", "a", "p", "h1"],
  //   allowedAttributes: { a: ["href"] }
  // };

  handleChange = (event) => {
    if (this.props.onChange) {
      return this.props.onChange(event.target.value);
    }
  };

  render = () => {
    return (
      <ContentEditable
        className={this.props.className}
        placeholder={this.props.placeholder}
        // innerRef={this.contentEditable}
        html={this.props.value} // innerHTML of the editable div
        disabled={!this.state.editable} // use true to disable editing
        onChange={this.handleChange} // handle innerHTML change
        tagName="content-editor" // Use a custom HTML tag (uses a div by default)
      />
    );
  };
}

// const styles = (theme) => ({
//   messageItemText: {
//     fontSize: "1em",
//     fontFamily: theme.typography.fontFamily
//   },
// });

export default ContentEditor;
// export default withStyles(styles)(ContentEditor);
