import { combineReducers } from "redux";
import * as postingActions from "./postingsActions";

import { reducer as postingDetail } from "./postingDetail/postingDetailSlice";
export * from "./postingsAttachments";

// reducer
export const postings = (state = [], action) => {
  switch (action.type) {
    case postingActions.FETCH:
      return action.payload;
    case postingActions.FETCH_BY_ESTATE_ID:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  postings,
  postingDetail,
});
