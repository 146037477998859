import React from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LockIcon from "@material-ui/icons/LockOutlined";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import { withFormik } from "formik";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { apiClient } from "../../apiClient/apiClient";
import { route as signInRoute } from "../SignInContainer/SignInContainer";
import { injectIntl } from "react-intl";

const styles = (theme) => ({
  layout: {
    paddingTop: theme.spacing(8),
    width: "auto",
    display: "block", // Fix IE11 ticket.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3,
    )}px`,
  },
  avatar: {
    margin: theme.spacing(),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE11 ticket.
    marginTop: theme.spacing(),
  },
  submit: {
    marginTop: theme.spacing(2),
  },
});

const PasswordLostValidationScheme = Yup.object().shape({
  email: Yup.string().email().required(),
});

// Wrap our form with the using withFormik HoC
const formControl = {
  // Transform outer props into form values
  mapPropsToValues: (props) => ({
    email: "",
  }),
  // Add a custom validation function (this can be async too!)
  validationSchema: PasswordLostValidationScheme,
  // Submission handler
  handleSubmit: async (
    values,
    { props, setSubmitting, setErrors, resetForm },
  ) => {
    const { email } = values;
    setSubmitting(true);
    try {
      const response = await apiClient().post("accounts/_beginPasswordReset", {
        emailAddress: email,
      });
      if (response.status === 200) {
        resetForm();
        setSubmitting(false);
        props.history.push(signInRoute);
      }
    } catch (err) {
      console.error(err);
      setSubmitting(false);
    }
  },
};

const PasswordLostForm = (props) => {
  const {
    classes,
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    errors,
    intl: { formatMessage },
  } = props;

  return (
    <React.Fragment>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>
          <Typography variant="h5">
            {formatMessage({ id: "app.forgot.password" })}
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">Email</InputLabel>
              <Input
                error={!!touched.email && !!errors.email}
                id="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="email"
                autoFocus
              />
            </FormControl>

            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="secondary" //secondary / Primary
              className={classes.submit}
            >
              {formatMessage({ id: "passwordLostForm.sendMail" })}
            </Button>
            <Button
              fullWidth
              className={classes.submit}
              component={Link}
              to={signInRoute}
            >
              {formatMessage({ id: "passwordLostForm.backToSignIn" })}
            </Button>
          </form>
        </Paper>
      </main>
    </React.Fragment>
  );
};

PasswordLostForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  injectIntl,
  withStyles(styles),
  withRouter,
  withFormik(formControl),
)(PasswordLostForm);
