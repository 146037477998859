import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient } from "../../../../apiClient/apiClient";
import { updateTicketAsync } from "../../ticketApi";
import { TICKET_STATES } from "../../ticketConstants";

export const fetchTicketByIdThunk = createAsyncThunk(
  "ticket/currentTicket/fetch",
  async (id) => {
    const response = await apiClient().get(`/communications/tickets/${id}`);
    return response.data;
  }
);

export const updateTicketByIdThunk = createAsyncThunk(
  "ticket/update",
  async ({ id, data }, { dispatch }) => {
    try {
      const responseData = await updateTicketAsync(id, data);
      return responseData;
    } catch (error) {
      console.error(error);
      // try to fetch the ticket again on error, to get an updated version
      await dispatch(fetchTicketByIdThunk(id));
    }
  }
);

// specific ticket-manager operations
// accept ticket
export const taskAcceptThunk = createAsyncThunk(
  "ticket/ticket-detail/ticket-manager/task-accept",
  async ({ assigneeId, ticket }, { dispatch }) => {
    try {
      const ticketCopy = {
        ...ticket,
        assigneeIds: [...ticket.assigneeIds, assigneeId],
        processingState: TICKET_STATES.IN_PROGRESS,
      };

      await dispatch(
        updateTicketByIdThunk({ id: ticket.id, data: ticketCopy })
      );
    } catch (e) {
      console.error(e);
    }
  }
);

// close ticket
export const taskCompleteThunk = createAsyncThunk(
  "ticket/ticket-detail/ticket-manager/task-complete",
  async ({ ticket, taskComplete, assigneeId }, { dispatch }) => {
    if (taskComplete) {
      const ticketCopy = {
        ...ticket,
        assigneeIds: ticket.assigneeIds.filter((id) => id !== assigneeId),
        processingState: TICKET_STATES.WORK_DONE,
      };

      await dispatch(
        updateTicketByIdThunk({ id: ticket.id, data: ticketCopy })
      );
    } else {
      const ticketCopy = {
        ...ticket,
        assigneeIds: ticket.assigneeIds.filter((id) => id !== assigneeId),
        processingState: TICKET_STATES.NEW,
      };

      await dispatch(
        updateTicketByIdThunk({ id: ticket.id, data: ticketCopy })
      );
    }
  }
);
