import { combineReducers } from "redux";
import * as estateActions from "./estateActions";

// estates reducer
export const estates = (estates = [], action) => {
  switch (action.type) {
    case estateActions.SET_ESTATES:
      return action.payload;

    default:
      return estates;
  }
};

// current estate reducer
export const currentEstate = (currentEstate = {}, action) => {
  switch (action.type) {
    case estateActions.SET_CURRENT_ESTATE:
      return action.payload;

    default:
      return currentEstate;
  }
};

export default combineReducers({
  estates,
  currentEstate,
});
