import { fetchPropertyManagementByIdAsync } from "./propertyManagementApi";

// actions
export const SET_CURRENT = "propertyManagement/SET_CURRENT";

// action creators
export const setPropertyManagement = (propertyManagement) => ({
  type: SET_CURRENT,
  payload: { propertyManagement },
});

// operations
export const getPropertyManagement = (propertyManagementId) => {
  return async (dispatch) => {
    try {
      const response = await fetchPropertyManagementByIdAsync(
        propertyManagementId
      );

      if (response.status === 200 && response.data) {
        // set the current selected estate for operations
        dispatch(setPropertyManagement(response.data));
      }
    } catch (err) {
      console.error("Problems while fetching current estate.", err);
    }
  };
};
