import { combineReducers } from "redux";

import { reducer as currentTicketListFilter } from "./ticketListFilter/ticketListActions";
import {
  ticketsReducer as ticketsList,
  ticketsMessagesReducer,
} from "./ticketList/ticketsActions";
import { reducer as readTicketsIds } from "./ticketsRead/ticketsReadActions";
import ticketDetail from "./ticketDetail";

export default combineReducers({
  ticketDetail,
  currentTicketListFilter,
  ticketsMessagesReducer,
  ticketsList,
  readTicketsIds,
});
