import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";

// styling
import Paper from "@material-ui/core/Paper";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";

import HomeIcon from "@material-ui/icons/Home";
import PersonIcon from "@material-ui/icons/Person";
import FolderIcon from "@material-ui/icons/FolderShared";
// routes
import { route as ticketsRoute } from "../../containers/TicketListContainer/TicketListContainer";
import { route as homeRoute } from "../../containers/HomeContainer/HomeContainer";
import { route as profileRoute } from "../../containers/ProfileContainer/ProfileContainer";
import { route as documentRoute } from "../../containers/DocumentListContainer/DocumentListContainer";

import { setMainNavigationOption } from "../../modules/navigation/navigationActions";
import { nonOptimalStates } from "../../functions";
import TicketChatContainer from "../TicketChatContainer";
import { ticketIsPrivate } from "../../functions/ticketIsPrivate";
import AnnouncementIconWithBadge from "./AnnouncementIconWithBadge";
import { ROLES } from "../../modules/accounts/accountConstants";
import { accountHasRole } from "../../modules/accounts/accountFunctions";

// intl
const messages = {
  home: {
    id: "mainNavigation.home",
    defaultMessage: "Home",
  },
  tickets: {
    id: "mainNavigation.tickets",
    defaultMessage: "Notifications",
  },
  myPropertyManagement: {
    id: "mainNavigation.myPropertyManagement",
    defaultMessage: "My PM",
  },
  profile: {
    id: "mainNavigation.profile",
    defaultMessage: "Profile",
  },
};

function MainNavigation(props) {
  const {
    intl: { formatMessage },
  } = props;
  // TODO: can't we get this info from history or router match?
  const value = props.currentNavigationOption;
  const handleChange = (event, value) => {
    if (props.setMainNavigationOption) {
      props.setMainNavigationOption(value);
    }
  };

  return (
    <Paper
      style={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation value={value} onChange={handleChange} showLabels>
        <BottomNavigationAction
          component={Link}
          label={formatMessage(messages.home)}
          icon={<HomeIcon />}
          // @ts-ignore inherited from react-router-dom link
          to={homeRoute}
          value={homeRoute}
        />

        <BottomNavigationAction
          component={Link}
          label={formatMessage(messages.tickets)}
          icon={<AnnouncementIconWithBadge />}
          // @ts-ignore inherited from react-router-dom link
          to={ticketsRoute}
          value={ticketsRoute}
        />

        <BottomNavigationAction
          component={Link}
          label={formatMessage(messages.myPropertyManagement)}
          icon={<FolderIcon />}
          // @ts-ignore inherited from react-router-dom link
          to={documentRoute}
          value={documentRoute}
        />

        <BottomNavigationAction
          component={Link}
          label={formatMessage(messages.profile)}
          icon={<PersonIcon />}
          // @ts-ignore inherited from react-router-dom link
          to={profileRoute}
          value={profileRoute}
        />
      </BottomNavigation>
    </Paper>
  );
}

const findItem = (id) => (value) => Boolean(value.id === id);

/**
 *
 * @param {*} props
 */
const isDetailViewAndMessageEnabled = (props) => {
  if (!props.match.params && !props.match.params.ticketId) {
    return false;
  }

  const item =
    props.tickets &&
    props.tickets.ticketsList &&
    props.tickets.ticketsList.find(
      findItem(Number(props.match.params.ticketId)),
    );

  const userIsTicketManager = accountHasRole(
    props.currentAccount,
    ROLES.TICKET_MANAGER,
  );

  const isPrivate = ticketIsPrivate(item);

  // except for ticket managers, only the ticket creator and the estate can see private messages
  return Boolean(item && isPrivate) || Boolean(item && userIsTicketManager);
};

const mapStateToProps = ({
  tickets,
  currentNavigationOption,
  currentAccount,
}) => ({
  tickets,
  currentNavigationOption,
  currentAccount,
});

export default compose(
  injectIntl,
  connect(mapStateToProps, { setMainNavigationOption }),
  nonOptimalStates([
    {
      when: isDetailViewAndMessageEnabled,
      render: TicketChatContainer,
    },
  ]),
)(MainNavigation);
