import React from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { injectIntl } from "react-intl";
import { compose } from "recompose";

const DetailsSection = ({
  classes,
  touched,
  errors,
  values,
  handleChange,
  intl: { formatMessage },
}) => {
  return (
    <section className={classes.section}>
      <div className={classes.form}>
        <TextField
          error={
            touched && errors && touched.subject && errors.subject
              ? true
              : false
          }
          // label="Betreff"
          label=" "
          id="subject"
          name="subject"
          // variant="outlined"
          value={values.subject}
          className={classes.textField}
          onChange={handleChange}
          autoFocus
          helperText={
            touched && errors && touched.subject && errors.subject
              ? formatMessage({
                  id: "ticket.create.detail.error.wrong_subject",
                })
              : formatMessage({ id: "ticket.create.detail.subject" })
          }
        />

        <TextField
          error={
            touched && errors && touched.place && errors.place ? true : false
          }
          // label="Ort"
          label=" "
          id="place"
          name="place"
          className={classes.width100}
          value={values.place}
          onChange={handleChange}
          helperText={
            touched && errors && touched.place && errors.place
              ? formatMessage({
                  id: "ticket.create.detail.error.invalid_place",
                })
              : formatMessage({ id: "ticket.create.detail.place" })
          }
          // variant="outlined"
        />

        <FormControl fullWidth>
          <TextField
            error={
              touched && errors && touched.body && errors.body ? true : false
            }
            // label="Beschreibung"
            label=" "
            id="body"
            name="body"
            value={values.body}
            multiline
            rows="4"
            className={classes.width100}
            onChange={handleChange}
            helperText={
              touched && errors && touched.body && errors.body
                ? formatMessage({
                    id: "ticket.create.detail.error.invalid_description",
                  })
                : formatMessage({ id: "ticket.create.detail.details" })
            }
            // variant="outlined"
          />
        </FormControl>
      </div>
    </section>
  );
};

export default compose(injectIntl)(DetailsSection);
