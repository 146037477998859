import { getRandomInt } from "../../functions";

const imageURLSource = [
  "https://images.unsplash.com/photo-1478428036186-d435e23988ea?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80",
  "https://png.pngtree.com/thumb_back/fw800/back_pic/03/98/54/6657f4df1585845.jpg",
  "https://s3-eu-west-1.amazonaws.com/images.danb.me/trello-backgrounds/pink.jpg",
  "http://www.tinybirdgames.com/wp-content/uploads/2017/04/tinybirdgames_telegram_background_02.jpg",
];

const tileData = [
  {
    img: imageURLSource[getRandomInt(3)],
    featured: true,
    subject: "Hier befinden sich ihre Aushänge",
    body: "Begrüßungstext, verschwindet mit dem nächsten 'Echten' Aushang",
    createdAt: new Date(),
    id: "324523523",
    anonymous: false,
    hasAttachments: false,
    published: true,
  },
];

export default tileData;
