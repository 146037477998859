import React from "react";
import PropTypes from "prop-types";
import { AttachFile as AttachFileIcon } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import { withCurrentUser, getTimeFromNow } from "./../functions";
import {
  cutContent,
  ticketsFactory,
  nonOptimalStates,
  mapPrivateOrPublicMessages,
} from "./../functions";
import ProcessIndicator from "./ProcessIndicator/ProcessIndicator";
import { compose, mapProps } from "recompose";
import { injectIntl } from "react-intl";

/**
 *
 * @param {*} props
 */
const MetaDataContainer = (props) => {
  const formatMessage = props.intl.formatMessage;
  return (
    <Typography>
      {formatMessage({ id: "ticket.anonymous.label.limbo" })}
    </Typography>
    // Weder öffentlich noch Privat
  );
};

/**
 *
 * @param {*} props
 */
const isPrivate = (props) => Boolean(props.isPrivate);

/**
 *
 * @param {*} props
 */
const isPublic = (props) => Boolean(props.isPublic);

/**
 *
 * @param {*} props
 */
const Anonymous = (props) => {
  const formatMessage = props.intl.formatMessage;
  return (
    <Typography color="inherit">
      {`${formatMessage({ id: "ticket.anonymous.label" })}: ${
        props.anonymous
          ? formatMessage({ id: "ticket.anonymous.value.yes" })
          : formatMessage({ id: "ticket.anonymous.value.no" })
      }`}
    </Typography>
  );
};

/**
 *
 * @param {*} props
 */
const PrivateContainer = (props) => {
  const formatMessage = props.intl.formatMessage;
  return (
    <div className={props.classes.metaInfo}>
      <Typography color="inherit" style={{ marginRight: "0.5rem" }}>
        {formatMessage({ id: "ticket.anonymous.label.private" })}
      </Typography>
      <Anonymous {...props.item} />
    </div>
  );
};

/**
 *
 * @param {*} props
 */
const PublicContainer = (props) => {
  const formatMessage = props.intl.formatMessage;
  return (
    <div className={props.classes.metaInfo}>
      <Typography color="inherit" style={{ marginRight: 10 }}>
        {formatMessage({ id: "ticket.anonymous.label.public" })}
      </Typography>
      <Anonymous {...props.item} />
      {/* <Group {...props.item} /> */}
    </div>
  );
};

/**
 *
 */
const MetaDataDecider = nonOptimalStates([
  {
    when: isPrivate,
    render: PrivateContainer,
  },
  {
    when: isPublic,
    render: PublicContainer,
  },
])(MetaDataContainer);

/**
 *
 */
export const MetaInformation = compose(
  withCurrentUser(),
  mapProps(mapPrivateOrPublicMessages),
  injectIntl
)(MetaDataDecider);

/**
 *
 */
class MessageListItem extends React.PureComponent {
  render() {
    const { classes, item, openTicket, isMarked } = this.props;
    const cardStyle = isMarked ? classes.cardMarked : classes.card;
    // Cut content to a length of 140, avoids an exceptios with Math.min
    // in case the content is already shorter than 140.
    const content = item.body ? `${cutContent(item.body)} ...` : "";
    const { hasAttachments, getProperty, getServiceType, hasProperty } =
      ticketsFactory(item);

    return (
      <Card className={cardStyle} onClick={() => openTicket(item.id)}>
        <CardContent>
          <div className={classes.head}>
            <Typography className={classes.title} variant="h5" component="h2">
              {item.subject}
            </Typography>
            <div className={classes.state}>
              <Typography color="textSecondary" className={classes.flex}>
                <ProcessIndicator status={getProperty("processingState")} />
              </Typography>
            </div>
          </div>

          <Typography variant="body1">{content}</Typography>

          <div className={classes.footer}>
            <div className={classes.footer}>
              {hasAttachments() && <AttachFileIcon />}
              {/* Render serviceType is available */}
              {hasProperty("serviceType") && (
                <Typography>
                  Betrifft Kategorie: <strong>{getServiceType("name")}</strong>
                </Typography>
              )}
            </div>

            <Typography color="textSecondary">
              {getTimeFromNow(item.createdAt)}
            </Typography>
          </div>
        </CardContent>
        <CardActions>
          <MetaInformation item={item} />
        </CardActions>
        {/* <CardContent>
          <MetaInformation item={item} />
        </CardContent> */}
      </Card>
    );
  }
}

MessageListItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  card: {
    width: "100%",
    borderLeft: "3px solid transparent",
  },
  // there may be a better solution for conditional styles with
  // https://github.com/mui-org/material-ui/issues/7633
  cardMarked: {
    width: "100%",
    borderLeft: "3px solid",
    borderLeftColor: theme.palette.secondary.main,
  },
  flex: { display: "flex", alignItems: "center" },
  head: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: ".5rem",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: { fontSize: 18 },
  state: {
    fontSize: 14,
    textAlign: "right",
    textTransform: "uppercase",
  },
  pos: {
    marginBottom: 12,
  },
});

export default compose(withStyles(styles, { withTheme: true }))(
  MessageListItem
);
