import { apiClient } from "../../apiClient/apiClient";

// actions
export const SET_RELATIONS = "user/SET_RELATIONS";

// action creators
const setCurrentRelation = (user) => ({ type: SET_RELATIONS, payload: user });

// operations
export const fetchRelationsByPersonId = (userId) => {
  return async (dispatch) => {
    try {
      const userResponse = await apiClient().get(`persons/${userId}/relations`);
      if (userResponse.status === 200) {
        const { data } = userResponse;
        dispatch(setCurrentRelation(data));
      }
    } catch (err) {
      console.error("Problems while fetching account or session data.", err);
    }
  };
};

// reducer

export const reducer = (relations = [], action) => {
  switch (action.type) {
    case SET_RELATIONS:
      return action.payload;

    default:
      return relations;
  }
};
