import sanitizeHTML from "sanitize-html";

export const sanitizeAllowed = (htmlString) =>
  sanitizeHTML(htmlString, {
    allowedTags: ["p", "em", "strong"],
    allowedClasses: {
      p: ["fancy", "simple"],
    },
    allowedIframeHostnames: ["www.hausify.de"],
  });
