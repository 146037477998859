import { apiClient } from "../../apiClient/apiClient";
import merge from "lodash/merge";
/**
 *
 * @param {*} options
 */
export const fetchDocumentsAsync = (options) => {
  const defaultOptions = {
    params: {
      sort: `["createdAt","DESC"]`,
      range: "[0, 999]",
    },
  };
  return apiClient().get("documents", merge(defaultOptions, options));
};

/**
 *
 * @param {*} options
 */
export const fetchCategoriesAsync = (options) => {
  const defaultOptions = {
    params: {
      sort: `["createdAt","DESC"]`,
    },
  };
  return apiClient().get(
    "documents/_categories",
    merge(defaultOptions, options)
  );
};
