import React, { Fragment } from "react";

// import ArrowIcon from "@material-ui/icons/ArrowForward";
// import amber from "@material-ui/core/colors/amber";
// import blue from "@material-ui/core/colors/blue";
// import green from "@material-ui/core/colors/green";
import { getStateByID } from "../../functions";
import { injectIntl } from "react-intl";
import { getProcessIconByProcessingState } from "./getProcessIconByProcessingState";

/**
 *
 * @param {*} param0
 */
const StateTextAndIconIndicator = ({
  status,
  intl: { formatMessage },
  ...rest
}) => {
  const stateType = getStateByID(status);

  if (stateType) {
    return (
      <Fragment>
        <span style={{ marginRight: ".5rem" }}>
          {formatMessage({ id: stateType.i18n })}{" "}
        </span>
        <span>{getProcessIconByProcessingState(status)}</span>
      </Fragment>
    );
    // return (
    //   <Fragment>
    //     {stateType.name} {getProcessIcon(status)}
    //   </Fragment>
    // );
  }
  console.warn(`Status "${status}" is not available at present!`);
  return null;
};

/**
 *
 * @param {*} param0
 */
export const StateIconIndicator = ({ status }) => {
  const stateType = getStateByID(status);

  if (stateType) {
    return getProcessIconByProcessingState(status);
  }
  console.warn(`Status "${status}" is not available at present!`);
  return null;
};

export default injectIntl(StateTextAndIconIndicator);
