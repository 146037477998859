import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { MitteilungenFolderList } from "../../components/Dashboard";
import {
  mapTicketsWithFiltersToProps,
  listHandler,
} from "../TicketListContainer/TicketListContainer";
import { FolderItemContainer } from "../../components/Common/FolderItem";
import { ViewTitle } from "../../components/Common";
import { injectIntl } from "react-intl";
import { getServiceTypeLanguageIdByName } from "../../modules/ticket/ticketConstants";
import { getStateByID } from "../../functions";
import { Divider } from "@material-ui/core";

/**
 * Following fields need translation
 * processingState = "New"
 * serviceType = {
 *  id: 32
 *  name: "Graffiti"
 * }
 * @param {*} formatMessage
 */
const formatDocument = (formatMessage) => (document) => {
  const newDocument = { ...document };

  if (newDocument.serviceType && newDocument.serviceType.name) {
    newDocument.serviceType.name = formatMessage({
      id: getServiceTypeLanguageIdByName(newDocument.serviceType.name),
    });
  }

  if (newDocument.processingState) {
    const state = getStateByID(newDocument.processingState);

    if (state && state.i18n) {
      newDocument.processingStatei18n = formatMessage({ id: state.i18n });
    }
  }

  return newDocument;
};

/**
 * Dashboard component. Container for tickets related to a Liegenschaft
 */
function MitteilungenContainer(props) {
  const {
    classes,
    title,
    intl: { formatMessage },
    ...rest
  } = props;
  const formatDocumentWithIntl = formatDocument(formatMessage);
  return (
    <React.Fragment>
      {title && <ViewTitle title={title} styles={{ marginTop: 12 }} />}

      {/* Alle postings/Mitteilungen die pro estate reinkommen */}
      <MitteilungenFolderList
        disablePadding={rest.disablePadding}
        light={rest.light}
        subheader={props.subheader}
        data={props.tickets}
      >
        {(ticket, index) => (
          <React.Fragment key={`${ticket.id}-${index}`}>
            <FolderItemContainer
              {...rest} // How many arguments are necessary
              document={formatDocumentWithIntl(ticket)}
              onItemClick={props.handleItemClick}
              readTicketsIds={props.readTicketsIds}
            />
            <Divider component="li" />
          </React.Fragment>
        )}
      </MitteilungenFolderList>
    </React.Fragment>
  );
}

export default compose(
  listHandler,
  injectIntl,
  connect(mapTicketsWithFiltersToProps)
)(MitteilungenContainer);
