import { apiClient } from "../../../apiClient/apiClient";

const resource = "communications/tickets";
const defaultOptions = {
  params: {
    sort: `["createdAt","DESC"]`,
  },
};

export const fetchTickets = (options) =>
  apiClient().get(resource, options || defaultOptions);
