import { createSlice } from "@reduxjs/toolkit";
import { fetchPostingByIdThunk } from "./postingDetailOperations";

const slice = createSlice({
  name: "currentPosting",
  initialState: {
    isLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPostingByIdThunk.pending, () => ({ isLoading: true }));
    builder.addCase(fetchPostingByIdThunk.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    });
  },
});

const selectSelf = (state) => state.postings.postingDetail;
const selectPostingDetail = (state) => selectSelf(state);

export const postingDetailSelectors = { selectPostingDetail };

export const reducer = slice.reducer;
