import React from "react";
import { withFormik } from "formik";
import { compose } from "recompose";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import { addNewMessage } from "../../modules/ticket/ticketForm/ticketFormActions";
import { FileInput } from "../../components/FileInput";
import { fetchMessagesByTicketIdThunk } from "../../modules/ticket/ticketDetail/messages/messageListOperations";
import sanitizeHtml from "sanitize-html";

import {
  Divider,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";

import {
  Send as SendIcon,
  RemoveCircleOutline as DeleteIcon,
  InsertDriveFile as FileIcon,
} from "@material-ui/icons";
/**
 *
 * @param {*} theme
 */
const styles = (theme) => ({
  fileList: {},

  chatForm: {
    display: "flex",
    alignItems: "center",
  },

  textInput: {
    flexGrow: 1,
  },

  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
});

// Our inner form component which receives our form's state and updater methods as props
const TicketsChatForm = (props) => {
  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    classes,
    setValues,
  } = props;

  const handleFileInputChange = (fileList) => {
    // manually set the list of attachments for formik
    setValues({ ...values, files: fileList });
  };

  const handleFileRemove = (id) => {
    const sanitizedFiles = values.files.filter((file) => !(id === file.id));
    // manually set the list of attachments for formik
    setValues({ ...values, files: sanitizedFiles });
  };

  return (
    <div>
      <List dense={true}>
        {values.files.map((file) => (
          <ListItem key={file.id}>
            <ListItemIcon>
              <FileIcon />
            </ListItemIcon>
            <ListItemText>{file.name}</ListItemText>
            <ListItemSecondaryAction>
              <IconButton onClick={() => handleFileRemove(file.id)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>

      <form onSubmit={handleSubmit} className={classes.chatForm}>
        <TextField
          id="content"
          name="content"
          value={values.content}
          onChange={handleChange}
          disabled={isSubmitting}
          placeholder="Mitteilung schreiben ..."
          multiline
          className={classes.textInput}
          rowsMax={5}
        />

        <FileInput color="primary" onChange={handleFileInputChange} />

        <Divider className={classes.divider} />
        <IconButton
          color="primary"
          aria-label="Send"
          type="submit"
          disabled={isSubmitting}
        >
          <SendIcon />
        </IconButton>
      </form>
    </div>
  );
};

const sanitizeConf = {
  allowedTags: ["b", "br", "i", "em", "strong", "a", "p", "h1"],
  allowedAttributes: { a: ["href"] },
};

// Wrap our form with the using withFormik HoC
const formControl = {
  // Transform outer props into form values
  mapPropsToValues: () => {
    return {
      content: "",
      files: [],
    };
  },

  // Submission handler
  handleSubmit: (values, { props, setSubmitting, setErrors, resetForm }) => {
    // Set form to submitting
    setSubmitting(true);

    const tempContent = values.content.split("\n").join("<br>");
    values.content = sanitizeHtml(tempContent, sanitizeConf);

    const message = {
      id: props.itemId,
      content: values.content,
      attachments: values.files,
    };

    if (props.dispatch) {
      return props
        .dispatch(addNewMessage(message))
        .then(() => {
          // reload message list
          props.dispatch(fetchMessagesByTicketIdThunk(props.itemId));
          resetForm();
          setSubmitting(false);
        })
        .catch((err) => {
          setSubmitting(false);
          setErrors(err.message);
        });
    }
  },
};

export default compose(
  connect(),
  withStyles(styles),
  withFormik(formControl)
)(TicketsChatForm);
