import { apiClient } from "../../../apiClient/apiClient";
import {
  createTicket,
  updateTicket as updateAction,
  addMessage,
} from "../ticketList/ticketsActions";
import connect from "react-redux/lib/connect/connect";

export const COMMUNICATIONS_TICKETS = "communications/tickets";

/**
 * Creates a new ticket
 * @param {*} ticketBody Object of type Ticket
 */
const createNewTicket = (ticketBody) => {
  return async (dispatch) => {
    const response = await apiClient({
      // `onUploadProgress` allows handling of progress events for uploads
      onUploadProgress: (progressEvent) => {
        // const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        // console.log({
        //   percentCompleted
        // });
      },
    }).post(COMMUNICATIONS_TICKETS, ticketBody);
    //
    dispatch(createTicket(response.data));
  };
};

export const withCreateNewTicket = connect(null, {
  createNewTicket,
});

/**
 *
 * @param {*} ticketBody
 */
export const updateTicket = (ticketBody) => {
  return async (dispatch) => {
    const { id, ...rest } = ticketBody;
    const response = await apiClient().put(
      `${COMMUNICATIONS_TICKETS}/${id}`,
      rest
    );
    dispatch(updateAction(response.data));
  };
};

/**
 *
 * @param {*} ticketMessage
 */
export const addNewMessage = ({ id, content, attachments }) => {
  return async (dispatch) => {
    const response = await apiClient().post(
      `${COMMUNICATIONS_TICKETS}/${id}/messages`,
      {
        content,
        attachments,
      }
    );
    dispatch(addMessage(response.data));
  };
};
