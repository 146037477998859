import React, { useState } from "react";

import { TextField, InputAdornment, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import LocationIcon from "@material-ui/icons/LocationOn";
import { setCurrentEstateById } from "../modules/estate/estateActions";

import { compose } from "recompose";
import { withCurrentEstate, withEstates } from "../functions";
import { fullAddress } from "../functions/users";

/**
 * @description Menu to select an estate and update it globally.
 * @see https://protofy.atlassian.net/browse/HAUS-454 Add filter to reduce large lists.
 */
function EstateSelect(props) {
  const { estates, currentEstate } = props;
  const [isOpen, setIsOpen] = useState(false);

  function handleChange(_, option) {
    props.dispatch(setCurrentEstateById(option.value));
  }

  const options = estates.map((estate) => ({
    name: fullAddress(estate.address),
    value: estate.id,
  }));

  const optionsSorted = sortOptions(options);

  const currentOption = {
    name: fullAddress(currentEstate.address),
    value: currentEstate.id,
  };

  const renderInput = (params) => {
    const handleClickAdornment = () => {
      // close select if it is already open
      isOpen ? setIsOpen(false) : setIsOpen(true);
    };
    return (
      <TextField
        {...params}
        InputProps={{
          ...params.InputProps,
          onClick: handleClickAdornment,
          // I found no other solution to style the component than using style directly.
          style: { color: "white" },
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <LocationIcon />
            </InputAdornment>
          ),
        }}
      />
    );
  };

  return estates.length === 1 ? (
    <div style={{ display: "flex", alignItems: "center" }}>
      <LocationIcon style={{ marginRight: 4 }} />
      <Typography>{fullAddress(currentEstate.address)}</Typography>
    </div>
  ) : (
    <React.Fragment>
      <Autocomplete
        // fullWidth
        id="estate-select"
        options={optionsSorted}
        getOptionLabel={(option) => option.name || ""}
        getOptionSelected={(option, value) => {
          return option.value === value.value;
        }}
        value={currentOption}
        onChange={handleChange}
        disableClearable={true}
        renderInput={renderInput}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </React.Fragment>
  );
}

const sortOptions = (options) => {
  return options.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  });
};

export default compose(withCurrentEstate(), withEstates())(EstateSelect);
