import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "account",
  initialState: {},
  reducers: {
    setData: (state, action) => {
      return action.payload;
    },
  },
});

// selectors
const selectSelf = (state) => state.currentAccount;

export const accountSelectors = {
  selectAccountId: (state) => selectSelf(state).accountId,
  selectRoles: (state) => selectSelf(state).roles,
  selectCurrentAccount: selectSelf,
};

// actions
export const accountActions = slice.actions;

// reducer
export const reducer = slice.reducer;
