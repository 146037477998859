import moment from "moment";

const formatFullDate = "dddd, MMMM Do YYYY";

/**
 *
 * @param {*} date
 */
export const formatTime = (date) => moment(date).format(formatFullDate);

/**
 * @param {*} date
 */
export const getTimeFromNow = (date) => moment(date).fromNow();
