import hausifyProfileHeader from "./hausify_liegenschaft.jpg";
import hausifyLogoTransparent from "./hausify_transparent.png";
import hausifyAuthPwLostBg from "./auth_pw_lost_bg.jpg";
import hausifySignInBg from "./auth_sign_in_bg.jpg";

export {
  hausifyProfileHeader,
  hausifyLogoTransparent,
  hausifyAuthPwLostBg,
  hausifySignInBg,
};
