/**
 *
 * @param {*} currentUser
 * @param {*} separator
 */
export const fullName = (currentUser, separator) =>
  `${currentUser.firstName}${separator} ${currentUser.lastName}`;

/**
 *
 * @param {*} address
 */
export const fullAddress = (address) => {
  if (!Boolean(address)) {
    return void 0;
  }

  const addressText = `${address.street || ""} ${address.postalCode || ""} ${address.city || ""}`;

  return addressText;
};

export const fullAddressNoPostalCode = (address) => {
  if (!Boolean(address)) {
    return void 0;
  }

  const addressText = `
      ${address.street || ""} ${address.city || ""}
    `;

  return addressText;
};
