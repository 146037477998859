// Hausify DEMO HV (Prod) (Mustermann)

import { createTheme } from "@material-ui/core/styles";
// import amber from "@material-ui/core/colors/amber";
import green from "@material-ui/core/colors/orange";

export default createTheme({
  paper: {
    tertiary: {
      ///hausify hellgrau f2f2f2
      main: "#FFFDED",
      text: "#000",
    },
  },
  palette: {
    primary: {
      ///hausify blau 00a2c7
      main: "#F2DB94",
    },
    secondary: {
      ///hausify orange FCC332 (zb Head-Zeile Dropdown)
      // light
      // dark
      // FeSt Hausverwaltung #DAB000
      main: "#3F9CA6",
      contrastText: "#FFFDED",
    },
    notification: {
      ticketBadge: {
        // The number configures the shade
        main: green[500],
        text: "#FFFDED",
      },
    },
    importNotification: {
      main: "#4C5759",
      text: "#FFFDED",
    },
    ticketsNotification: {
      ///hausify orange FCC332 (zb Meine Verwaltung)
      main: "#787378",
      text: "#FFFDED",
    },
    estateNotification: {
      ///hausify rosa e3cfde
      main: "#e3cfde",
      contrastText: "#333",
    },
    postingsNotification: {
      ///hausify blau 00a2c7
      main: "#2E338C",
      text: "#FFFDED",
    },

    documentsNotification: {
      main: "#FFFDED",
      contrastText: "#333333",
      text: "#333333",
    },
    explainerBox: {
      main: "#777",
    },
  },
  typography: {
    fontFamily: ["roboto", "sans-serif", "serif"].join(","),
  },
});
