import { combineReducers } from "redux";
import { reducer as assignees } from "./assigneeList";
import { reducer as currentTicket } from "./currentTicket/currentTicketSlice";
import { reducer as messages } from "./messages";

export default combineReducers({
  assignees,
  currentTicket,
  messages,
});
