import React from "react";
import { connect } from "react-redux";
import Badge from "@material-ui/core/Badge";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import { getUnreadTicketsCount } from "../../modules/ticket/ticketsRead/ticketsReadActions";

const mapStateToProps = ({ tickets }) => ({
  tickets,
});

const AnnouncementIconWithBadge = (props) => {
  const { ticketsList, readTicketsIds } = props.tickets;
  const ticketIndicatorCount = getUnreadTicketsCount(
    ticketsList,
    readTicketsIds,
  );

  return ticketIndicatorCount ? (
    <Badge
      color="secondary"
      overlap="rectangular"
      badgeContent={Number(ticketIndicatorCount)}
    >
      <AnnouncementIcon />
    </Badge>
  ) : (
    <AnnouncementIcon />
  );
};

export default connect(mapStateToProps)(AnnouncementIconWithBadge);
