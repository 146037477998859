import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";

import { Link } from "react-router-dom";
import { getImageSRC } from "../../functions/assets";

const styles = (theme) => {
  return {
    ImageList: {
      flexWrap: "nowrap",
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: "translateZ(0)",
    },
    ImageListItem: {
      backgroundColor: theme.palette.grey[200],
      borderRadius: theme.shape.borderRadius,
    },
  };
};

function PostingSingleLineImageList(props) {
  const { classes, defaultData, data } = props;
  const postings = data.length ? data : defaultData;
  const postingsPublished = postings.filter((posting) => posting.published);

  return (
    <ImageList className={classes.ImageList} cols={2.5}>
      {postingsPublished.map((tile) => {
        return (
          <ImageListItem
            classes={{ item: classes.ImageListItem }}
            key={tile.id}
            component={Link}
            to={`/postings/${tile.id}`}
          >
            {tile.attachments &&
              tile.attachments.map((attachment) => {
                return (
                  <img
                    src={getImageSRC(attachment.previewFilename)}
                    alt={attachment.name}
                    key={attachment.id}
                  />
                );
              })}
            <ImageListItemBar title={tile.subject} />
          </ImageListItem>
        );
      })}
    </ImageList>
  );
}

PostingSingleLineImageList.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array,
  defaultData: PropTypes.array,
};

PostingSingleLineImageList.defaultProps = {
  data: [],
};

export default withStyles(styles)(PostingSingleLineImageList);
