import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";

import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LockIcon from "@material-ui/icons/LockOutlined";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import { withFormik } from "formik";
import { withRouter } from "react-router-dom";
import { HTTP_UNAUTHORIZED } from "../../apiClient/apiClient";
import { compose } from "recompose";
// import { connect } from "react-redux";
import {
  // requestSessionToken,
  withRequestSessionToken,
} from "../../modules/session/sessionActions";
import SignInValidationScheme from "./SignInValidationScheme";
import { route as homeRoute } from "./../HomeContainer/HomeContainer";
import { route as passwordLostRoute } from "../PasswordLostContainer/PasswordLostContainer";
import { withShowNotificationMessage } from "../../modules/uiState";

const styles = (theme) => ({
  layout: {
    paddingTop: theme.spacing(8),
    width: "auto",
    display: "block", // Fix IE11 ticket.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3,
    )}px`,
  },
  avatar: {
    margin: theme.spacing(),
    backgroundColor: theme.palette.secondary,
  },
  form: {
    width: "100%", // Fix IE11 ticket.
    marginTop: theme.spacing(),
  },
  submit: {
    marginTop: theme.spacing(2),
  },
  error: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(),
  },
});

// Wrap our form with the using withFormik HoC
const formControl = {
  // Transform outer props into form values
  mapPropsToValues: (props) => ({
    emailAddress: "",
    password: "",
  }),

  // Add a custom validation function (this can be async too!)
  validationSchema: SignInValidationScheme,
  // Submission handler
  handleSubmit: (
    values,
    { props, setSubmitting, setErrors, resetForm, errors },
  ) => {
    const { formatMessage } = props.intl;
    const { emailAddress, password } = values;
    setSubmitting(true);

    props
      .requestSessionToken(emailAddress, password)
      .catch((err) => {
        // let submitErrorMessage = {};
        if (err.code === HTTP_UNAUTHORIZED) {
          // submitErrorMessage = formatMessage({
          //   id: "ErrorMessage.unauthorized"
          // });
          props.showNotificationMessage(
            formatMessage({
              id: "ErrorMessage.unauthorized",
            }),
          );
        }

        setSubmitting(false);
      })
      .finally((_) => {
        props.history.push(homeRoute);
      });
  },
};

const SignIn = (props) => {
  const {
    classes,
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    errors,
  } = props;
  const { formatMessage } = props.intl;
  return (
    <React.Fragment>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>
          <Typography variant="h5">
            {formatMessage({ id: "app.login", defaultMessage: "Login" })}
          </Typography>
          <Typography variant="subtitle1">
            {formatMessage({ id: "subheader.login" })}
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="emailAddress">Email</InputLabel>
              <Input
                error={touched.emailAddress && Boolean(errors.emailAddress)}
                id="emailAddress"
                name="emailAddress"
                type="email"
                value={values.emailAddress}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="email"
                autoFocus
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Passwort</InputLabel>
              <Input
                error={touched.password && Boolean(errors.password)}
                name="password"
                type="password"
                id="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="current-password"
              />
            </FormControl>
            {errors.submitErrorMessage && (
              <Typography variant="subtitle1" className={classes.error}>
                {errors.submitErrorMessage}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary" //secondary / Primary
              className={classes.submit}
            >
              {formatMessage({ id: "app.login" })}
            </Button>

            <Button
              component={Link}
              to={passwordLostRoute}
              fullWidth
              className={classes.submit}
            >
              {formatMessage({ id: "app.forgot.password" })}
            </Button>
          </form>
        </Paper>
      </main>
    </React.Fragment>
  );
};

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  injectIntl,
  withStyles(styles),
  withShowNotificationMessage,
  withRequestSessionToken,
  withFormik(formControl),
)(SignIn);
