import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// import Input from '@material-ui/core/Input';
import { branch, renderComponent, compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withCurrentEstate } from "./../../functions";
import { hasEntries } from "./../../functions";
import { fullAddress } from "../../functions/users";
import { injectIntl } from "react-intl";
import { getImageSRC } from "../../functions/assets";
// import { getEstateByPropertyManagementId } from '../../modules/estate/estateActions';

// HELPER //
const getFreetextFromArray = (estateArray) => {
  const freetextArray = estateArray.filter(([field, val]) =>
    field.includes("freetext"),
  );
  return freetextArray;
};

const NoPropertyAvailable = (props) => {
  return (
    <React.Fragment>
      <Typography variant="body1" gutterBottom>
        Estate
      </Typography>
      <div>No estate available</div>
    </React.Fragment>
  );
};

const noEsateFieldAvailable = ({ estate }) => estate == null;

const noPropertyAvailable = branch(
  noEsateFieldAvailable,
  renderComponent(NoPropertyAvailable),
);

const styles = (theme) => ({
  freetext: {
    marginBottom: theme.spacing(),
    marginLeft: theme.spacing(1.5),
    fontFamily: theme.typography.fontFamily,
  },

  image: {
    display: "block",
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
    // marginLeft: "auto",
    // marginRight: "auto",
    margin: `${theme.spacing(2)}px auto ${theme.spacing(2)}px auto`,
    width: "50%",
  },

  address: {
    width: "100%",
    margin: `${theme.spacing(2)}px 0 0 ${theme.spacing()}px`,
  },

  infoText: {
    width: "100%",
    margin: `${theme.spacing(2)}px 0 0 ${theme.spacing()}px`,
  },

  logo: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    // marginTop: "15px",
    marginBottom: "20px",
    // width: x < DEFAULT_APP_WIDTH ? "75%" : "50%",
  },
});

// const InputText = (props) => (
//   <Input
//     {...props}
//     readOnly
//     disableUnderline
//     multiline
//   />
// );

const filterFreetext = ([field, freetext]) => freetext;

const renderFreetexts =
  ({ classes }) =>
  ([field, freetext], index) => {
    return (
      <div
        key={`${field}-${index}`}
        className={classes.freetext}
        dangerouslySetInnerHTML={{ __html: freetext }}
      />
    );
    // return (
    //   <Typography key={`${field}-${index}`} variant="body1" className={classes.freetext}>
    //     <div dangerouslySetInnerHTML={{ __html: freetext }} />
    //   </Typography>
    // );
    // return (
    //   <InputText
    //     className={classes.freetext}
    //     type="textarea"
    //     margin="none"
    //     id={field}
    //     infoText={field}
    //     name={field}
    //     defaultValue={freetext}
    //   />
    // );
  };

class PropertyManagementProfile extends React.PureComponent {
  // UNSAFE_componentWillReceiveProps(newProps) {
  //   const { estate, classes } = newProps;
  //   console.log(estate);
  //   getEstateByPropertyManagementId(estate.id).then(console.log)
  // }

  render() {
    const {
        estate,
        classes,
        currentEstate,
        intl: { formatMessage },
      } = this.props,
      // address from estates
      { address, logo } = estate,
      // freetext array ala [[field, value], ...]
      freetextArray = getFreetextFromArray(
        Object.entries(estate || Object.create(null)),
      );
    console.log(estate);
    return (
      <React.Fragment>
        <Grid container spacing={24} style={{ textAlign: "center" }}>
          <Grid item xs={12} sm={12}>
            {logo && (
              <div className={classes.logo}>
                <img
                  className={classes.image}
                  src={getImageSRC(logo.filename)}
                  alt={logo.name}
                />
              </div>
            )}
            {estate && <Typography variant="h5">{estate.name}</Typography>}
            {estate && <Typography variant="h6">{estate.infoText}</Typography>}

            {address && (
              <Typography variant="subtitle1" style={{ marginBottom: "20px" }}>
                {fullAddress(address)}
              </Typography>
            )}
            {address && estate && (
              <Typography variant="h6"> {estate.emailAddress}</Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" align="left" gutterBottom>
              {formatMessage({ id: "profile.property_management.headline" })}
            </Typography>
          </Grid>
          <React.Fragment>
            {hasEntries(freetextArray) &&
              freetextArray
                .filter(filterFreetext)
                .map(renderFreetexts({ classes }))}
          </React.Fragment>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" align="left" gutterBottom>
              {formatMessage({
                id: "profile.property_management.info_your_estate",
              })}
            </Typography>
            {currentEstate && currentEstate.infoText && (
              <Typography variant="subtitle1" align="left">
                {currentEstate.infoText}
              </Typography>
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default compose(
  noPropertyAvailable,
  injectIntl,
  withStyles(styles),
  withCurrentEstate(),
)(PropertyManagementProfile);
