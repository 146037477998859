import React from "react";
import { Modal } from "react-responsive-modal";

export const ImageDetailModal = ({ imageSourceForModal, closeModal }) => {
  return (
    <Modal open={Boolean(imageSourceForModal)} onClose={closeModal} center>
      <div style={{ paddingTop: "24px" }}>
        <img
          src={imageSourceForModal}
          style={{ maxWidth: "100%" }}
          alt="detail in modal"
        />
      </div>
    </Modal>
  );
};
