import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import ListItem from "@material-ui/core/ListItem";

import { StateIconIndicator } from "../ProcessIndicator/ProcessIndicator";
import { getTimeFromNow, getStateByID, sanitizeAllowed } from "../../functions";
import { mapProps } from "recompose";
import { Typography, Avatar, Chip } from "@material-ui/core";

import { getImageSRC, getMimeType } from "../../functions/assets";
import { selectEstateById } from "../../modules/estate/estateSelectors";
import { accountSelectors } from "../../modules/accounts/accountSlice";
import { ROLES } from "../../modules/accounts/accountConstants";
import { accountHasRole } from "../../modules/accounts/accountFunctions";
import { ticketIsPrivate } from "../../functions/ticketIsPrivate";

// This is actually the ticket item
const FolderItem = (props) => {
  const { classes, secondaryText, ...rest } = props;
  const dotStyle = rest.isMarked
    ? { backgroundColor: "orange" }
    : { backgroundColor: "lightgrey" };
  const isMarkedStyle = rest.isMarked
    ? { fontWeight: "bold" }
    : { fontWeight: "normal" };

  const isPrivate = ticketIsPrivate(props);

  const estate = props.estate;
  const estateAddress = estate && estate.address;

  let attachmentOne = undefined;
  try {
    attachmentOne = rest.attachments[0];
  } catch (error) {
    console.error("No attachment provided.", error);
  }

  return (
    <ListItem
      button
      onClick={() => rest.onClick(rest.id)}
      style={{ display: "block" }}
    >
      {/* top row */}
      <div
        className={classes.row}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{ ...dotStyle, marginRight: ".5rem" }}
          className={classes.dot}
        />

        <div style={{ marginRight: ".5rem" }}>
          {rest.createdAt && (
            <Typography
              variant="body2"
              className={classes.serviceType}
              style={{
                ...isMarkedStyle,
              }}
            >
              {rest.createdAt}
            </Typography>
          )}
        </div>
        <div style={{ flexGrow: 1, marginRight: ".5rem" }}>
          {isPrivate && <Chip label="privat" style={{ height: "1rem" }} />}
        </div>
        <StateIconIndicator status={rest.processingState} />
      </div>

      {/* title row */}
      <div
        className={classes.row}
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "nowrap",
        }}
      >
        <Typography
          variant="body1"
          style={{ ...isMarkedStyle, marginRight: ".5rem" }}
        >
          {rest.subject}
        </Typography>

        <div>
          {attachmentOne && (
            <Avatar
              alt={attachmentOne && getMimeType(attachmentOne.mimeType)}
              src={`${getImageSRC(
                attachmentOne.previewFilename
              )}&scalePercent=5`}
              className={classes.preview}
            />
          )}
        </div>
      </div>

      {!Boolean(rest.dense) && (
        <Typography
          dangerouslySetInnerHTML={{ __html: sanitizeAllowed(rest.body) }}
        />
      )}

      {/* bottom row */}
      {props.userIsTicketManager && estateAddress && (
        <div className={classes.row}>
          <Typography variant="body2" className={classes.serviceType}>
            {estateAddress.street} - {estateAddress.postalCode}{" "}
            {estateAddress.city}
          </Typography>
        </div>
      )}
    </ListItem>
  );
};
/**
 *
 * @param {*} props
 */
const mapTicketPropsToListItem = (props) => {
  const { document, ...rest } = props,
    stateType = getStateByID(document.processingState),
    // Don't show author name when anonymous
    documentAuthor = !Boolean(document.anonymous) ? document.author : "",
    // whenIndicator = !Boolean(document.anonymous) ? "am" : "",
    createdAt = document.updatedAt
      ? getTimeFromNow(document.updatedAt)
      : getTimeFromNow(document.createdAt);

  return {
    ...document,
    ...rest,
    createdAt,
    stateType,
    isMarked: !props.readTicketsIds.includes(document.id),
    // Check whether this is a default message or not.
    // default comes in as true or false
    secondaryText: !Boolean(document.default) && documentAuthor,
  };
};

const styles = (theme) => {
  return {
    row: { marginBottom: "0.4rem" },
    // preview image in list
    preview: {
      borderRadius: 0,
      border: "1px solid #ccc",
    },

    serviceType: {
      fontSize: theme.typography.pxToRem("12px"),
      color: "rgba(0, 0, 0, 0.54)",
    },

    dot: {
      height: "12px",
      width: "12px",
      borderRadius: "50%",
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  const currentAccount = accountSelectors.selectCurrentAccount(state);
  const userIsTicketManager = accountHasRole(
    currentAccount,
    ROLES.TICKET_MANAGER
  );
  return {
    estate: selectEstateById(state, ownProps.document.estateId),
    userIsTicketManager,
  };
};

export const FolderItemContainer = compose(
  connect(mapStateToProps),
  withStyles(styles),
  mapProps(mapTicketPropsToListItem)
)(FolderItem);
