import React, { PureComponent } from "react";

import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Loader } from "../../components/Common";

import TicketDetailAppBar from "../TicketDetailContainer/TicketDetailAppBar";
import PostingDetail from "./PostingDetail";
import { fetchPostingByIdThunk } from "../../modules/postings/postingDetail/postingDetailOperations";
import { postingDetailSelectors } from "../../modules/postings/postingDetail/postingDetailSlice";

export const route = "/postings/:postingId";

const styles = (theme) => ({
  root: {
    position: "relative",
    paddingTop: "4rem", // gap for fixed tab bar

    backgroundColor: theme.palette.background.whitesmoke,
    marginBottom: "3rem",
  },

  loader: {
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

/**
 *
 */
class PostingDetailContainer extends PureComponent {
  static route = route;

  componentDidMount() {
    this.props.dispatch(
      fetchPostingByIdThunk(Number(this.props.match.params.postingId))
    );
  }

  render() {
    const { classes, posting } = this.props;

    return (
      <div className={classes.root}>
        <TicketDetailAppBar />

        {/* Content */}

        {posting.isLoading ? (
          <div className={classes.loader}>
            <Loader />
          </div>
        ) : (
          <PostingDetail item={posting} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const postingId = ownProps.match.params.postingId;
  const posting = postingDetailSelectors.selectPostingDetail(state, postingId);
  return { posting };
};

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps)
)(PostingDetailContainer);
