import axios from "axios";
import store from "../store";
import { getSessionToken } from "../modules/session/functions";
import { showNotificationMessage } from "../modules/uiState";
import { defineMessages } from "react-intl";

const API_URL = process.env.REACT_APP_API;
export const HTTP_UNAUTHORIZED = 401;
export const HTTP_INTERNAL_SERVER_ERROR = 500;
export const HTTP_STATUS_CONFLICT = 409;
export const HTTP_STATUS_TO_MANY_REQUESTS = 429;

export const apiClientHooks = {
  onUnauthorized: [],
};

/**
 *
 */
const translate = defineMessages({
  networkError: {
    id: "app.error.network_error",
    defaultMessage: "Es sieht so aus, als wären Sie offline...",
  },
});

// handle 401 requests
const addUnauthorizedInterceptor = (client) =>
  client.interceptors.response.use(null, (err) => {
    // worst case / show message and cancel request
    if (err.message === "Network Error") {
      store.dispatch(
        showNotificationMessage(
          // missing formatMessage
          translate.networkError.defaultMessage
          // "Es sieht so aus, als wären Sie offline..."
        )
      );
      return;
    }

    if (err.response.status === HTTP_UNAUTHORIZED) {
      apiClientHooks.onUnauthorized.forEach((fn) => fn());
      // return;
    }

    return Promise.reject(err);
  });

export const apiClient = (options) => {
  const token = getSessionToken();

  const apiClient = axios.create({
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${token}`,
    },
    // `baseURL` will be prepended to `url` unless `url` is absolute.
    baseURL: API_URL,
    // upload, timout, download, ..usw
    ...options,
  });

  addUnauthorizedInterceptor(apiClient);

  return apiClient;
};
