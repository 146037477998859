// This code converts a file to base64.
// It is used in the image upload component to convert an image to base64 so that it can be uploaded to the server.
export function getBase64ContentFromFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(",")[1]);
    reader.onerror = (error) => reject(error);
  });
}

// This function takes a File object (https://developer.mozilla.org/en-US/docs/Web/API/File)
// and returns a Promise that resolves to the file's contents as a base64-encoded string.
// It is used to convert a file into a format that can be sent to the server.
export function getDataUrlFromFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    try {
      reader.readAsDataURL(file);
      reader.onload = resolve(reader.result.split(",")[1]);
      console.log(reader);
    } catch (err) {
      console.error(err);
      reject(err);
    }
  });
}
