import {
  getEntriesCount,
  getReadTicketListFromPersistance,
} from "../../../functions";

const ADD_READ_TICKET = "tickets/ADD_READ_TICKET";
const SET_TICKETS_READ = "tickets/SET_TICKETS_READ";

export const addReadTicket = (ticketId) => ({
  type: ADD_READ_TICKET,
  payload: ticketId,
});

export const setTicketsRead = (tickets) => ({
  type: SET_TICKETS_READ,
  payload: tickets,
});

export const setTicketRead = (ticketId) => {
  return (dispatch) => dispatch(addReadTicket(ticketId));
  // TODO: persist to session storage
};

const initialState = getReadTicketListFromPersistance() || [];

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_READ_TICKET:
      return [...state, action.payload];

    case SET_TICKETS_READ:
      return action.payload;

    default:
      return state;
  }
};

export function getUnreadTicketsCount(ticketsList, readTicketsIds) {
  const currentTicketIds = ticketsList.map((ticket) => ticket.id);
  const unreadTicketIds = currentTicketIds.filter(
    (currentTicketId) => !readTicketsIds.includes(currentTicketId)
  );
  return getEntriesCount(unreadTicketIds);
}
