import React, { Fragment } from "react";
import { IconButton, Typography, withStyles } from "@material-ui/core";
import {
  NavigateBefore,
  NavigateNext,
  FirstPage,
  LastPage,
  SaveAlt,
  // Fullscreen,
  Remove,
  Add,
  // FullscreenExit,
  Close,
} from "@material-ui/icons";
// import cn from "classnames";
import { compose } from "recompose";

const styles = (theme) => {
  return {
    nav: {
      backgroundColor: theme.palette.common.white,
      padding: "0 54px",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.31)",
      left: 0,
      right: 0,
    },
    navDesktop: {
      position: "relative",
    },
    navTop: {
      position: "fixed",
      padding: "8px 48px",
      top: 0,
    },
    navBottom: {
      position: "fixed",
      padding: "8px 56px",
      boxShadow: "0 -2px 4px 0 rgba(0, 0, 0, 0.31)",
      bottom: 0,
    },
  };
};

const Controls = (props) => {
  const {
    numPages,
    downloadFile,
    page,
    scale,
    onScale,
    onSetPage,
    // fullscreen,
    // onToggleFullScreen,
    children,
    onClose,
    classes,
    currentSigUrl,
  } = props;

  console.log("currentSigUrl", currentSigUrl);

  // const classNames = cn({
  //     [classes.nav]: true,
  //     [classes.navTop]: fullscreen,
  //     [classes.navDesktop]: !fullscreen,
  // });

  const ScaleButtons = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <PdfControlButton
          disabled={scale <= 0.25}
          onClick={() => onScale(scale - 0.25)}
        >
          <Remove />
        </PdfControlButton>
        <Typography variant="body1" color="primary">
          {scale * 100}%
        </Typography>
        <PdfControlButton onClick={() => onScale(scale + 0.25)}>
          <Add />
        </PdfControlButton>
      </div>
    );
  };

  const SkipButtons = () => {
    return (
      <Fragment>
        <PdfControlButton disabled={page === 1} onClick={() => onSetPage(1)}>
          <FirstPage />
        </PdfControlButton>
        <PdfControlButton
          disabled={page === 1}
          onClick={() => onSetPage(page - 1)}
        >
          <NavigateBefore />
        </PdfControlButton>

        <Typography variant="body1" color="primary">
          {page} / {numPages}
        </Typography>

        <PdfControlButton
          disabled={page === numPages}
          onClick={() => onSetPage(page + 1)}
        >
          <NavigateNext />
        </PdfControlButton>
        <PdfControlButton
          disabled={page === numPages}
          onClick={() => onSetPage(numPages)}
        >
          <LastPage />
        </PdfControlButton>
      </Fragment>
    );
  };

  const downloadButton = (
    // <Fragment>
    //     <a style={{ fontSize: "24px" }} href={currentSigUrl} target="_blank" download={currentSigUrl}>
    //         (dl,blank)
    //     </a>
    //     <a style={{ fontSize: "24px" }} href={currentSigUrl} target="_self" download={currentSigUrl}>
    //         (dl,self)
    //     </a>
    //     <a style={{ fontSize: "24px" }} href={currentSigUrl} target="_blank">
    //         (no dl,blank)
    //     </a>
    //     <a style={{ fontSize: "24px" }} href={currentSigUrl} target="_self">
    //         (no dl,self)
    //     </a>
    //     <a style={{ fontSize: "24px" }} href={currentSigUrl}>
    //         (no dl, no target)
    //     </a>
    //     <a style={{ fontSize: "24px" }} href={currentSigUrl} target="_self" download={currentSigUrl}>
    //         (dl, no target)
    //     </a>
    <PdfControlButton onClick={downloadFile}>
      <SaveAlt />
    </PdfControlButton>
    // </Fragment>
  );

  // const fullscreenButton = (
  //     <PdfControlButton onClick={onToggleFullScreen}>
  //         {fullscreen ? <FullscreenExit /> : <Fullscreen />}
  //     </PdfControlButton>
  // );

  const closeButton = (
    <PdfControlButton onClick={onClose}>
      <Close />
    </PdfControlButton>
  );

  return (
    <Fragment>
      {/* <Hidden smDown>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0 6px"

                    }}
                    className={classNames}
                >
                    <ScaleButtons />
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}>
                        <SkipButtons />
                    </div>
                    <div>
                        {fullscreenButton}
                        {downloadButton}
                    </div>
                </div>
            </Hidden> */}
      {/* <Hidden mdUp> */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 6px",
        }}
        className={[classes.nav, classes.navTop].join(" ")}
      >
        {downloadButton}
        {/* <span /> */}
        <ScaleButtons />
        {closeButton}
      </div>
      {children}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
        }}
        className={[classes.nav, classes.navBottom].join(" ")}
      >
        <SkipButtons />
      </div>
      {/* </Hidden> */}
    </Fragment>
  );
};

const PdfControlButton = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    padding: "8px",
  },
}))(IconButton);

export default compose(withStyles(styles, { withTheme: true }))(Controls);
