import React, { Component } from "react";
import LayoutContentCenteredWithBackground from "../../components/Layouts/ContentCenteredWithBackgroundLayout";
import PasswordLostForm from "./PasswordLostForm";

export const route = "/password-lost";

export default class PasswordLostContainer extends Component {
  static route = route;
  render() {
    return (
      <LayoutContentCenteredWithBackground>
        <PasswordLostForm />;
      </LayoutContentCenteredWithBackground>
    );
  }
}
