import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import purple from "@material-ui/core/colors/purple";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { Typography } from "@material-ui/core";

const factor = 1.2;

/**
 *
 * @param {*} theme
 */
const styles = (theme) => ({
  colorSwitchBase: {
    color: purple[300],
    "&$colorChecked": {
      color: purple[500],
      "& + $colorBar": {
        backgroundColor: purple[500],
      },
    },
  },
  colorBar: {},
  colorChecked: {},
  iOSSwitchBase: {
    "&$iOSChecked": {
      color: theme.palette.common.white,
      "& + $iOSBar": {
        backgroundColor: "#52d869",
      },
    },
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.sharp,
    }),
  },
  iOSChecked: {
    transform: `translateX(${15 * factor}px)`,
    "& + $iOSBar": {
      opacity: 1,
      border: "none",
    },
  },
  iOSBar: {
    borderRadius: 14 * factor,
    width: 42 * factor,
    height: 26 * factor,
    marginTop: -13 * factor,
    marginLeft: -20 * factor,
    border: "solid 1px",
    borderColor: theme.palette.grey[400],
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  iOSIcon: {
    width: 21 * factor,
    height: 21 * factor,
  },
  iOSIconChecked: {
    boxShadow: theme.shadows[1],
  },
});

class IOSwitch extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              classes={{
                switchBase: classes.iOSSwitchBase,
                bar: classes.iOSBar,
                icon: classes.iOSIcon,
                iconChecked: classes.iOSIconChecked,
                checked: classes.iOSChecked,
              }}
              disableRipple
              onChange={this.props.onChange}
              value={this.props.value}
              checked={this.props.checked}
            />
          }
          label={<Typography variant="body1">{this.props.label}</Typography>}
        />
      </FormGroup>
    );
  }
}

IOSwitch.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IOSwitch);
