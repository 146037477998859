import { combineReducers } from "redux";

import { reducer as currentAccount } from "./accounts/accountSlice";
import { reducer as currentNavigationOption } from "./navigation/navigationActions";
import { reducer as currentUser } from "./user/userActions";
import { reducer as propertyManagement } from "./propertyManagement";
import { reducer as relations } from "./relation/relationActions";
import { reducer as serviceTypes } from "./serviceTypes/serviceTypesActions";
import { reducer as sessionToken } from "./session/sessionActions";
import { reducer as uiState } from "./uiState";
import { reducer as documents } from "./documents";
import estates from "./estate";
import postings from "./postings";
import tickets from "./ticket";

const appReducer = combineReducers({
  currentAccount,
  currentNavigationOption,
  currentUser,
  estates,
  postings,
  propertyManagement,
  relations,
  serviceTypes,
  sessionToken,
  tickets,
  uiState,
  documents,
});

// clear all data on logout
const rootReducer = (state, action) => {
  if (action.type === "user/LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
