import React from "react";
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  IconButton,
} from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { PdfModal } from "../Modal/PdfModal";
import { ImageDetailModal } from "../Modal/ImageDetailModal";
import { getImageSRC } from "../../functions/assets";
import getAttachmentAsset from "../../apiClient/getAsset";

export class AttachmentListItem extends React.PureComponent {
  state = {
    modalOpen: false,
    source: "",
    mimeType: "",
    downloadUrl: null,
  };

  closeModal = () => {
    this.setState({
      ...this.state,
      modalOpen: false,
      source: "",
      mimeType: "",
    });
  };

  async componentDidMount() {
    const { item } = this.props;
    // get signed url for asset
    const downloadUrl = await getAttachmentAsset(item.filename);
    this.setState({ ...this.state, downloadUrl });
  }

  render() {
    const { item } = this.props;
    // console.log("state", this.state);
    return (
      <React.Fragment>
        <ListItem
          button
          onClick={(e) =>
            this.setState({
              ...this.state,
              modalOpen: true,
              source: getImageSRC(item.filename),
              mimeType: item.mimeType,
            })
          }
        >
          <ListItemAvatar>
            <Avatar
              style={{ borderRadius: ".5rem" }}
              src={getImageSRC(item.previewFilename) + "&scalePercent=20"}
              alt={item.name}
            />
          </ListItemAvatar>
          <ListItemText primary={item.name} />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="delete"
              download={item.filename}
              href={this.state.downloadUrl}
            >
              <CloudDownloadIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        {this.state.modalOpen && this.state.mimeType.includes("pdf") ? (
          <PdfModal source={this.state.source} closeModal={this.closeModal} />
        ) : this.state.mimeType.includes("image") ? (
          <ImageDetailModal
            imageSourceForModal={this.state.source}
            closeModal={this.closeModal}
          />
        ) : null}
      </React.Fragment>
    );
  }
}
