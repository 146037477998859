export const ALL = "ALL";

export const NEW = "NEW";
export const IN_PROGRESS = "IN_PROGRESS";
// export const ACCEPTED = "ACCEPTED";
export const SOLVED = "SOLVED"; // archived
export const WORK_DONE = "WORK_DONE";

export const DDMMYY = "DD.MM.YY";

export const processingStateTypes = [
  {
    id: NEW,
    name: "Neu",
    i18n: "ticket.processing_state.new",
  },
  // {
  //   id: ACCEPTED,
  //   name: "Freigegeben",
  //   i18n: "ticket.processing_state.accepted"
  // },
  {
    id: IN_PROGRESS,
    name: "In Bearbeitung",
    i18n: "ticket.processing_state.in_progress",
  },
  { id: WORK_DONE, name: "Erledigt", i18n: "ticket.processing_state.done" },
  // {
  //   id: SOLVED, name: "Archiviert", i18n: ""
  // },
  // { id: "NONE", name: "keine" }
];

export const publishingStateTypes = [
  // { id: "AUTO", name: "Nach Zeit" },
  { id: "PUBLISHED", name: "Aktiv" },
  { id: "UNPUBLISHED", name: "Inaktiv" },
];

export const relationTypes = [
  { id: "OWNER", name: "ist Eigentümer" },
  { id: "TENANT", name: "ist Mieter" },
  { id: "STAFF", name: "ist Mitarbeiter" },
  { id: "CONTRACTOR", name: "ist Dienstleister" },
  { id: "COUNSELOR", name: "ist Beirat" },
  { id: "TICKET_MANAGER", name: "ist Ticketmanager" },
];
