import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { compose, withHandlers } from "recompose";

const styles = (theme) => ({
  buttonText: {
    color: "#fff",
  },
  paddingLeft: {
    paddingLeft: 8,
  },
});

/**
 *
 */
class BackButton extends Component {
  render() {
    const { classes, className } = this.props;

    return (
      <Button
        className={className || classes.paddingLeft}
        onClick={this.props.onClick}
      >
        <ArrowBackIosIcon className={classes.buttonText} />
        {this.props.primaryText && (
          <Typography className={classes.buttonText}>
            {this.props.primaryText}
          </Typography>
        )}
      </Button>
    );
  }
}

const withBackLink = withHandlers({
  onClick: (props) => (event) => {
    const { history } = props;

    if (
      // This use case is when the user just got to the page and has no
      // history whatsoever...
      history.length === 1
    ) {
      return history.push("/tickets");
    }

    return history.goBack(event);
  },
});

BackButton.propTypes = {
  primaryText: PropTypes.string,
};

export default compose(
  withStyles(styles),
  withRouter,
  withBackLink
)(BackButton);
