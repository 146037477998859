import { apiClient } from "../../apiClient/apiClient";

export const getCurrentAccount = async (accountId) => {
  const account = await apiClient().get(`/accounts/${accountId}`);
  return account;
};

export const updateAccount = async (accountId, body) => {
  const { data } = await apiClient().put(
    `/accounts/${accountId}/notificationSettings`,
    body
  );
  return data;
};
