import React from "react";

import FormControl from "@material-ui/core/FormControl";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";
import { compose } from "recompose";
import { injectIntl } from "react-intl";
import { getServiceTypeLanguageIdByName } from "../../../modules/ticket/ticketConstants";

/**
 *
 * @param {*} theme
 */
const styles = (theme) => ({
  button: {
    borderRadius: 0,
    backgroundColor: theme.palette.background.paper,
    textTransform: "capitalize",
  },
  form: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
  step: {
    marginBottom: "1rem",
  },
  width100: {
    width: "100%",
  },
});

const ServiceTypeSelector = ({
  classes,
  serviceTypes,
  onNext,
  handleSetFieldValue,
  intl: { formatMessage },
}) => {
  return (
    <div className={classes.step}>
      <div className={classes.form}>
        {/* step 1 categories */}
        <FormControl component="fieldset" className={classes.width100}>
          <section className={classes.section}>
            <Typography>
              {formatMessage({ id: "ticket.create.select_type" })}
            </Typography>
            <List className={classes.list}>
              {serviceTypes.map((service) => {
                const serviceTypeNameTranslated = formatMessage({
                  id: getServiceTypeLanguageIdByName(service.name),
                });

                return (
                  <React.Fragment key={service.id}>
                    <Divider />
                    <ListItem
                      component={Button}
                      className={classes.button}
                      onClick={(ev) => {
                        handleSetFieldValue("serviceType", service.id);
                        onNext(ev);
                      }}
                    >
                      <ListItemText primary={serviceTypeNameTranslated} />

                      <ListItemSecondaryAction>
                        <IconButton
                          aria-label="next"
                          onClick={(ev) => {
                            handleSetFieldValue("serviceType", service.id);
                            onNext(ev);
                          }}
                        >
                          <ChevronRightIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </React.Fragment>
                );
              })}
            </List>
          </section>
        </FormControl>
      </div>
    </div>
  );
};

export default compose(withStyles(styles), injectIntl)(ServiceTypeSelector);
