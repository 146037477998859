import React, { Component } from "react";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "../../components/DefaultAppBar";
import TicketForm from "./TicketForm";
import TicketListToolBar from "../TicketListContainer/TicketListToolBar";

export const route = "/tickets/new";

const styles = (theme) => ({
  root: {
    position: "relative",
    paddingTop: "5rem", // gap for fixed tab bar
    paddingBottom: "7rem", // gap for fixed bottom navigation
  },
});

class TicketFormContainer extends Component {
  static route = route;

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar>
          <TicketListToolBar noTicketFilter />
        </AppBar>
        <TicketForm />
      </div>
    );
  }
}

export default compose(withStyles(styles, { withTheme: true }))(
  TicketFormContainer
);
