import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { route as HomeContainerRoute } from "../containers/HomeContainer/HomeContainer";

const PublicRoute = ({ component: Component, sessionToken, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      // console.log(props)
      return sessionToken ? (
        <Redirect
          to={{
            pathname: HomeContainerRoute,
            from: props.location,
          }}
        />
      ) : (
        <React.Fragment>
          <Component {...props} />
        </React.Fragment>
      );
    }}
  />
);

export default withRouter(
  connect(({ sessionToken }) => ({ sessionToken }))(PublicRoute)
);
