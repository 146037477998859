import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient } from "../../../../apiClient/apiClient";

export const COMMUNICATIONS_TICKETS = "communications/tickets";

export const fetchMessagesByTicketIdThunk = createAsyncThunk(
  "ticket/messages/fetch",
  async (id) => {
    const response = await apiClient().get(
      `${COMMUNICATIONS_TICKETS}/${id}/messages`,
      {
        params: {
          sort: `["createdAt","ASC"]`,
        },
      }
    );
    return response.data;
  }
);
