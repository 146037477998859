import { fetchDocumentsThunk } from "./documentOperations";

const { createSlice, createEntityAdapter } = require("@reduxjs/toolkit");

const entityAdapter = createEntityAdapter({
  selectId: (item) => item.id,
});

const slice = createSlice({
  name: "documents",
  initialState: {
    isLoading: false,
    ...entityAdapter.getInitialState(),
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDocumentsThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchDocumentsThunk.fulfilled, (state, action) => {
      entityAdapter.setAll(state, action.payload);
      state.isLoading = false;
    });
    builder.addCase(fetchDocumentsThunk.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const documentSelectors = entityAdapter.getSelectors(
  (state) => state.documents.documents
);

export const reducer = slice.reducer;
