import { SOLVED } from "../../../functions";
import { fetchTickets } from "./ticketsApi";

let timer;

const COMMUNICATIONS_TICKETS = "communications/tickets";

// Actions
const LOAD = `${COMMUNICATIONS_TICKETS}/LOAD`;
const CREATE = `${COMMUNICATIONS_TICKETS}/CREATE`;
const UPDATE = `${COMMUNICATIONS_TICKETS}/UPDATE`;
const REMOVE = `${COMMUNICATIONS_TICKETS}/REMOVE`;
const FETCH = `${COMMUNICATIONS_TICKETS}/FETCH`;

// Messages reducer
const ADD_MESSAGE = `${COMMUNICATIONS_TICKETS}/ADD_MESSAGE`;
const FETCH_MESSAGES = `${COMMUNICATIONS_TICKETS}/FETCH_MESSAGES`;

// Reducer
export const ticketsReducer = (state = [], action = {}) => {
  switch (action.type) {
    // do reducer stuff
    case CREATE:
      return [action.payload, ...state];

    case UPDATE:
      return;

    case REMOVE:
      return;

    case FETCH:
      return action.payload;

    default:
      return state;
  }
};

/**
 * Reducer only for Messages in tickets.
 * @param {*} state
 * @param {*} action
 */
export const ticketsMessagesReducer = (state = [], action = {}) => {
  switch (action.type) {
    case ADD_MESSAGE:
      return [...state, action.payload];

    case FETCH_MESSAGES:
      return action.payload;

    default:
      return state;
  }
};

// Action Creators
export const loadTickets = () => ({ type: LOAD });

export const fetchTicketsAction = (tickets) => ({
  type: FETCH,
  payload: tickets,
});

export const fetchTicketMessagesAction = (messages) => ({
  type: FETCH_MESSAGES,
  payload: messages || [],
});

export const createTicket = (ticket) => ({
  type: CREATE,
  payload: ticket,
});

export const updateTicket = (ticket) => ({
  type: UPDATE,
  ticket,
});

export const addMessage = (ticket) => ({
  type: ADD_MESSAGE,
  payload: ticket,
});

const filterUnresolvedTickets = (tickets) =>
  tickets.filter((ticket) => ticket.processingState !== SOLVED);

/**
 * operations
 */
export function fetchTicketsByEstateIdThunk(estateId) {
  return async (dispatch, getState) => {
    const state = getState();
    const { filteredServiceTypeIds } = state.currentUser;
    const filter = { estateId };
    if (filteredServiceTypeIds && filteredServiceTypeIds.length > 0) {
      filter.serviceTypeIds = filteredServiceTypeIds;
    }
    const response = await fetchTickets({
      params: {
        sort: `["updatedAt","DESC"]`,
        filter,
      },
    });

    if (response && response.data) {
      const dataWithoutArchivedTickets = filterUnresolvedTickets(response.data);
      dispatch(fetchTicketsAction(dataWithoutArchivedTickets));
    }

    // TODO: recursion should not be part of the operation
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(fetchTicketsByEstateIdThunk(estateId));
    }, 30000);
  };
}
