import React from "react";

// base container for whole site content
function Container({ children }) {
  return (
    <div
      style={{
        maxWidth: 768,
        minHeight: "100vh",
        height: "100%",
        margin: "0 auto",
        backgroundColor: "#f5f5f5",
      }}
    >
      {children}
    </div>
  );
}

export default Container;
