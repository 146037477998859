import React from "react";
import AppBar from "@material-ui/core/AppBar";

const DefaultAppBar = (props) => {
  const { classes } = props;
  return (
    <AppBar classes={classes} position="fixed" color="primary">
      {props.children}
    </AppBar>
  );
};

export default DefaultAppBar;
