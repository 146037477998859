import React, { useState } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DescriptionIcon from "@material-ui/icons/Description";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { PdfModal } from "../Modal/PdfModal";
import { ImageDetailModal } from "../Modal/ImageDetailModal";
import { IconButton } from "@material-ui/core";

/**
 * This component can be added to a ListItem.
 * @param {*} param0
 */
export const PanelComponent = ({ document }) => {
  const [imageSource, setImageSource] = useState(undefined);
  const [documentUri, setDocumentUri] = useState(undefined);
  const uri = `${process.env.REACT_APP_API}${document.filename}`;
  const isPDF = uri && uri.toLowerCase().endsWith(".pdf");

  const handlePreview = () => {
    if (isPDF) {
      setDocumentUri(uri);
    } else {
      setImageSource(uri);
    }
  };
  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 24px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <IconButton onClick={handlePreview}>
          <DescriptionIcon />
        </IconButton>
        <span onClick={handlePreview}>
          <ListItemText primary={document.subject} />
        </span>
      </div>

      {/* download button */}
      <div>
        <IconButton href={uri} download target="_blank">
          <DownloadIcon />
        </IconButton>
      </div>

      {documentUri && (
        <PdfModal
          source={documentUri}
          closeModal={() => setDocumentUri(undefined)}
        />
      )}

      <ImageDetailModal
        imageSourceForModal={imageSource}
        closeModal={() => setImageSource(undefined)}
      />
    </li>
  );
};

/**
 * TODO: Die Avatar icons sollen die Stati anzeigen.
 */
class DocumentFolderItem extends React.PureComponent {
  render() {
    const { document } = this.props;
    return (
      <ListItem
        button
        onClick={this.props.handleClick}
        component={this.props.component}
        document={document}
      >
        <DescriptionIcon />
        <ListItemText primary={document.subject} />
        <ListItemIcon>
          <KeyboardArrowRight />
        </ListItemIcon>
      </ListItem>
    );
  }
}

export default DocumentFolderItem;
