import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import { hausifyProfileHeader } from "../assets";
// import { compose } from 'recompose';
// import { withCurrentEstate } from '../../functions';

const styles = (theme) => ({
  card: {
    position: "relative",
  },
  media: {
    height: 140,
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  titleBar: {
    position: "absolute",
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    display: "block",
    background: "#ccc",
    opacity: 0.8,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    border: "1px solid #888",
  },
});

class ProfileHeader extends React.PureComponent {
  render() {
    const { classes, estate } = this.props;
    return (
      <Card square className={classes.card}>
        <CardMedia className={classes.media} image={hausifyProfileHeader} />
        <div className={classes.titleBar}>
          <Typography variant="subtitle1" color="inherit">
            {estate ? estate.name : "No name"}
          </Typography>
        </div>
      </Card>
    );
  }
}

ProfileHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  estate: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProfileHeader);
