import { createSlice } from "@reduxjs/toolkit";
import {
  fetchTicketByIdThunk,
  taskAcceptThunk,
  taskCompleteThunk,
  updateTicketByIdThunk,
} from "./currentTicketOperations";

const slice = createSlice({
  name: "currentTicket",
  initialState: {
    isLoading: false,
    ticketManagerTaskIsLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTicketByIdThunk.pending, () => ({ isLoading: true }));
    builder.addCase(fetchTicketByIdThunk.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    });
    builder.addCase(updateTicketByIdThunk.pending, () => ({ isLoading: true }));
    builder.addCase(updateTicketByIdThunk.rejected, (state) => ({
      ...state,
      isLoading: false,
    }));
    builder.addCase(updateTicketByIdThunk.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    });
    // specific ticket-manager operations
    // accept ticket
    builder.addCase(taskAcceptThunk.pending, (state) => {
      state.ticketManagerTaskIsLoading = true;
    });
    builder.addCase(taskAcceptThunk.fulfilled, (state) => {
      state.ticketManagerTaskIsLoading = false;
    });
    // complete ticket
    builder.addCase(taskCompleteThunk.pending, (state) => {
      state.ticketManagerTaskIsLoading = true;
    });
    builder.addCase(taskCompleteThunk.fulfilled, (state) => {
      state.ticketManagerTaskIsLoading = false;
    });
  },
});

const selectSelf = (state) => state.tickets.ticketDetail.currentTicket;
const selectCurrentTicket = (state) => selectSelf(state);

export const currentTicketSelectors = { selectCurrentTicket };

export const reducer = slice.reducer;
