import React from "react";
import bg from "../Theme/images/bg.jpg";

function LayoutContentCenteredWithBackground({ children }) {
  return (
    <div
      style={{
        backgroundColor: "black",
        height: "100vh",
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      {children}
    </div>
  );
}

export default LayoutContentCenteredWithBackground;
