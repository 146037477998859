import React, { Component } from "react";
import { compose, withHandlers } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppBar from "../../components/DefaultAppBar";
import TicketList from "./TicketList";
import TicketListToolBar from "./TicketListToolBar";
import { setTicketRead } from "../../modules/ticket/ticketsRead/ticketsReadActions";
import { ALL } from "../../functions";
import { TicketCreateButton } from "./TicketCreateButton";

export const route = "/tickets";

export const styles = (theme) => ({
  root: {
    position: "relative",
    paddingTop: "4rem", // gap for fixed tab bar
    backgroundColor: theme.palette.background.whitesmoke,
    marginBottom: "2rem",
  },

  tabs: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },

  badge: {
    right: "-14px",
  },
});

class TicketsContainer extends Component {
  render() {
    const { classes, tickets } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <TicketListToolBar />
        </AppBar>

        <TicketList
          tickets={tickets}
          onItemClick={this.props.handleItemClick}
          readTicketsIds={this.props.readTicketsIds}
          disablePadding
          // dense
        />

        <TicketCreateButton />
      </div>
    );
  }
}

export const TicketContainer = withStyles(styles, { withTheme: true })(
  TicketsContainer
);

TicketContainer.route = route;

/**
 *
 */
export const listHandler = withHandlers({
  handleItemClick: (props) => (id) => {
    props.setTicketRead(id);
  },
});

/**
 *
 */
// const defaultTicket = [{
//   accessibleByPersonIds: [],
//   accessibleByTypes: ["TENANT", "COUNSELOR", "STAFF", "OWNER", "CONTRACTOR"],
//   anonymous: false,
//   attachments: [],
//   attachmentsEnabled: true,
//   body: "Hier befinden sich in Zukuft ihre Mitteilungen",
//   id: -1,
//   published: true,
//   publishingMode: "PUBLISHED",
//   subject: "Hier befinden sich in Zukuft ihre Mitteilungen",
//   type: "Ticket",
//   default: true
// }];

/**
 *
 * @param {*} param0
 */
export const mapTicketsWithFiltersToProps = ({ tickets }) => {
  const filter = tickets.currentTicketListFilter,
    currentList = (tickets.ticketsList || []).filter((ticket) => {
      return filter === ALL || filter === ticket.processingState;
    });

  return {
    tickets: currentList,
    // ticketListFilter: tickets.currentTicketListFilter,
    readTicketsIds: tickets.readTicketsIds,
  };
};

export const withTicketsFiltersAndSetRead = connect(
  mapTicketsWithFiltersToProps,
  {
    setTicketRead,
  }
);

/**
 *
 */
export default compose(
  withTicketsFiltersAndSetRead,
  listHandler,
  withRouter
)(TicketContainer);
