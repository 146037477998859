import React, { Component } from "react";
import { compose } from "recompose";
import Typography from "@material-ui/core/Typography";
import AppBar from "./../../components/DefaultAppBar";
import { withStyles } from "@material-ui/core/styles";
import PropertyManagementNavigation from "./PropertyManagementNavigationContainer";
import LocationToolBar from "./../../components/LocationToolBar";

export const route = "/property-management";

class PropertyManagementContainer extends Component {
  static route = route;

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Typography
          className={classes.pageHeading}
          variant="h6"
          align="left"
        ></Typography>

        <AppBar position="static">
          <LocationToolBar />
        </AppBar>
        <PropertyManagementNavigation />
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.white,
    position: "relative",
    paddingTop: "4rem", // gap for fixed tab bar
    paddingBottom: "3rem", // gap for fixed bottom navigation
    height: "100%",
  },
  pageHeading: {
    marginBottom: "0.6rem",
    marginLeft: theme.typography.pxToRem(20),
  },
  center: {
    display: "flex",
    margin: "50% auto",
    fontSize: "10rem",
  },
});

export default compose(withStyles(styles))(PropertyManagementContainer);
