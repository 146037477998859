import { createTheme } from "@material-ui/core/styles";
import amber from "@material-ui/core/colors/amber";
import logoImage from "./images/logo.png";
import backgroundImage from "./images/bg.jpg";

const MAIN_BLUE = "#47C2DC";

export default createTheme({
  images: {
    logo: logoImage,
    background: backgroundImage,
  },

  paper: {
    tertiary: {
      main: "#f2f2f2",
      text: "#000",
    },
  },
  palette: {
    primary: {
      main: "#00a2c7",
    },
    secondary: {
      // light
      // dark
      main: MAIN_BLUE,
      contrastText: "#fff",
      // main: "#00a2c7"
    },
    notification: {
      ticketBadge: {
        // The number configures the shade
        main: amber[500],
        text: "#fff",
      },
    },
    importNotification: {
      main: amber[500],
      text: "#fff",
    },
    ticketsNotification: {
      main: "#fcc332",
      text: "#fff",
    },
    estateNotification: {
      main: "#e3cfde",
      contrastText: "#333",
    },
    postingsNotification: {
      main: MAIN_BLUE,
      text: "#fff",
    },

    documentsNotification: {
      main: "#eee",
      contrastText: "#333333",
      text: "#333333",
    },
    explainerBox: {
      main: "#777",
    },
  },
  typography: {
    fontFamily: ["Open Sans", "sans-serif", "serif"].join(","),
  },
});
