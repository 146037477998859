import React, { Component } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import TicketDetailItem from "../../components/TicketDetailView/TicketDetailItem";
import TicketDetailAppBar from "./TicketDetailAppBar";
import { Loader } from "../../components/Common";
import { fetchTicketByIdThunk } from "../../modules/ticket/ticketDetail/currentTicket/currentTicketOperations";
import { fetchMessagesByTicketIdThunk } from "../../modules/ticket/ticketDetail/messages/messageListOperations";
import { currentTicketSelectors } from "../../modules/ticket/ticketDetail/currentTicket/currentTicketSlice";

export const route = "/tickets/:ticketId";

const styles = (theme) => ({
  root: {
    position: "relative",
    paddingTop: "3rem", // gap for fixed tab bar
    backgroundColor: theme.palette.background.whitesmoke,
    marginBottom: "3rem",
  },

  loader: {
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

class TicketDetailContainer extends Component {
  static route = route;

  componentDidMount() {
    const props = this.props;
    try {
      // fetch ticket by id
      props.fetchTicketByIdThunk(props.match.params.ticketId);
      // fetch ticket messages by ticket id
      props.fetchMessagesByTicketIdThunk(props.match.params.ticketId);
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    const { ticket, classes } = this.props;

    return (
      <div className={classes.root}>
        <TicketDetailAppBar />
        {/* Content */}
        <React.Fragment>
          {ticket.isLoading ? (
            <div className={classes.loader}>
              <Loader />
            </div>
          ) : (
            <TicketDetailItem item={ticket} />
          )}
        </React.Fragment>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ticket: currentTicketSelectors.selectCurrentTicket(state),
  };
};
const mapDispatchToProps = {
  fetchTicketByIdThunk,
  fetchMessagesByTicketIdThunk,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles, { withTheme: true })
)(TicketDetailContainer);
