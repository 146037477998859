import React from "react";
import moment from "moment";
import Card from "@material-ui/core/Card";
import { CardHeader, Divider, Typography } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import AttachmentList from "../../components/AttachmentList/AttachmentList";

export default function PostingDetail(props) {
  const { item } = props;

  const timeFromNow = moment(item.createdAt).fromNow();
  const subheader = `${item.author} ${timeFromNow}`;

  return (
    <Card style={{ margin: "1rem" }}>
      <CardHeader title={item.subject} subheader={subheader} />

      {item.body && (
        <React.Fragment>
          <Divider />
          <CardContent>{renderDescription(item.body)}</CardContent>
        </React.Fragment>
      )}

      {item.attachments && (
        <React.Fragment>
          <Divider />
          <AttachmentList attachments={item.attachments} />
        </React.Fragment>
      )}
    </Card>
  );
}

const renderDescription = (body) => {
  const descriptionArray = body ? body.split(/\n/) : [];
  const descriptionRows = descriptionArray.map((row, index) => {
    return (
      <Typography key={index} style={{ minHeight: "19px" }}>
        {row.split()}
      </Typography>
    );
  });

  return <div>{descriptionRows}</div>;
};
