import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient } from "../../../../apiClient/apiClient";

export const fetchAssigneesByEstateThunk = createAsyncThunk(
  "ticket/assignees/fetch",
  async (estateId) => {
    const response = await apiClient().get(`/estates/${estateId}/assignees`);
    return response.data;
  }
);
