import React, { Component } from "react";
import LayoutContentCenteredWithBackground from "../../components/Layouts/ContentCenteredWithBackgroundLayout";
import AcceptInviteForm from "./AcceptInviteForm";

export const route = "/accept-invitation/:token";
export default class AcceptInviteContainer extends Component {
  static route = route;
  render() {
    return (
      <LayoutContentCenteredWithBackground>
        <AcceptInviteForm />
      </LayoutContentCenteredWithBackground>
    );
  }
}
