import { apiClient } from "../../apiClient/apiClient";

const SET_SERVICE_TYPES = "ticketForm/SET_SERVICE_TYPES";

const setServiceTypes = (types) => ({
  type: SET_SERVICE_TYPES,
  payload: types,
});

export const fetchServiceTypes = () => {
  return async (dispatch) => {
    const response = await apiClient().get("servicetypes");
    dispatch(setServiceTypes(response.data));
  };
};

export const reducer = (state = [], action) => {
  switch (action.type) {
    case SET_SERVICE_TYPES:
      return action.payload;

    default:
      return state;
  }
};
