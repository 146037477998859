import { apiClient, apiClientHooks } from "../../apiClient/apiClient";
import { fetchCurrentUserThunk } from "../user/userActions";
import connect from "react-redux/lib/connect/connect";
import store from "../../store";
import { clearState } from "../../localStorage";

/*
 @TODO refactor session token handling
 The is already handle by session reducer in store state. So the
 explicit local storage handling seems to be redundant.
 */
const SET_SESSION_TOKEN = "session/SET_SESSION_TOKEN";
const DELETE_SESSION_TOKEN = "session/DELETE_SESSION_TOKEN";

apiClientHooks.onUnauthorized.push(() => {
  store.dispatch(deleteSessionToken());
  clearState();
});

export const setSessionToken = (token) => ({
  type: SET_SESSION_TOKEN,
  payload: token,
});

export const deleteSessionToken = () => ({
  type: DELETE_SESSION_TOKEN,
});

export const hasAuthorizedRole = (roles) =>
  roles.some((role) => role === "ADMIN" || role === "OCCUPANT");

export const requestSessionToken = (emailAddress, password) => {
  return async (dispatch) => {
    try {
      const response = await apiClient().post("sessions", {
        emailAddress,
        password,
      });
      const { data } = response;

      // redirect if user role is not occupant
      // NOTE: Redirect only necessary when a the requirement says you
      //       can not login as a "Verwalter"
      //
      // if(!hasAuthorizedRole(data.user.roles)) {
      //   window.location = process.env.REACT_APP_MANAGEMENT_URL
      //   return
      // }
      dispatch(setSessionToken(data.token));
      dispatch(fetchCurrentUserThunk());

      return response;
    } catch (err) {
      console.error("Error while requesting user token.", err);
      return Promise.reject(err.response.data); // TODO: validate Errors
    }
  };
};

export const withRequestSessionToken = connect(null, {
  requestSessionToken,
});

export const completeInvitation = (token, password) => {
  return async (dispatch) => {
    try {
      const response = await apiClient().post("/accounts/_acceptInvitation", {
        token,
        password,
      });
      // console.log(response);
      const { data } = response;
      if (data.sessionToken && data.finished) {
        dispatch(setSessionToken(data.sessionToken));
        dispatch(fetchCurrentUserThunk());
      } else {
        return Promise.reject();
      }
    } catch (err) {
      console.error("Error while requesting user token.", err);
      return Promise.reject(err.response.data); // TODO: validate Errors
    }
  };
};

export const reducer = (state = "", action) => {
  switch (action.type) {
    case SET_SESSION_TOKEN:
      return action.payload;
    case DELETE_SESSION_TOKEN:
      return "";
    default:
      return state;
  }
};
