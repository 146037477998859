import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { branch, renderComponent } from "recompose";

/**
 *
 */
class TabContainerDefault extends React.PureComponent {
  render() {
    const { children, dir } = this.props;
    return (
      <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
        {children}
      </Typography>
    );
  }
}

/**
 *
 * @param {*} param0
 */
const hasNoPropComponent = ({ component }) => component == null;

/**
 *
 */
const withNoPropsComponent = branch(
  hasNoPropComponent,
  renderComponent(TabContainerDefault)
);

/**
 * <TabContainer component={Comp} componentProps={props} />
 */
class TabContainer extends React.PureComponent {
  render() {
    const { component, componentProps, dir } = this.props;
    const CustomComponent = component;
    return (
      <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
        <CustomComponent {...componentProps} />
      </Typography>
    );
  }
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
  component: PropTypes.node,
};

export default withNoPropsComponent(TabContainer);
