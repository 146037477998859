import React from "react";
import { withStyles } from "@material-ui/core";
import TicketChatForm from "../containers/TicketDetailContainer/TicketChatForm";

const styles = (theme) => ({
  root: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    backgroundColor: "white",
  },
  container: {
    margin: "0 auto",
    maxWidth: 768,
    padding: theme.spacing(2),
  },
});

class TicketChatContainer extends React.Component {
  render() {
    const { classes } = this.props;
    const ticketId = this.props.match.params.ticketId;
    return (
      ticketId && (
        <div className={classes.root}>
          <div className={classes.container}>
            <TicketChatForm itemId={ticketId} />
          </div>
        </div>
      )
    );
  }
}

export default withStyles(styles)(TicketChatContainer);
