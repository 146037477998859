export const getLanguage = () => {
  // Most browsers have a language stored and provide those
  const locale = navigator.language || "de";

  if (locale.includes("de")) {
    return "de";
  }

  return "en";
};
