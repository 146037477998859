import React, { useRef } from "react";
import { IconButton } from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { getBase64ContentFromFile } from "../encodeBase64";
import { v4 as uuid } from "uuid";
/**
 * File input
 * @param {props.onChange} function Callback function with an enriched files array as first param.
 */
export const FileInput = ({ onChange, ...rest }) => {
  const inputRef = useRef(null);

  const handleOnChange = (e) => {
    // map files
    const filesPromises = Array.from(e.target.files).map(async (file) => ({
      // prefixing is necessary to separate existing from new files
      // this will be used in `ticketSagas.js`
      id: `new_${uuid()}`,
      // lastModified: file.lastModified,
      // lastModifiedDate: file.lastModifiedDate,
      name: file.name,
      // size: file.size,
      mimeType: file.type,
      contentBase64: await getBase64ContentFromFile(file),
    }));
    Promise.all(filesPromises).then((files) => onChange(files));
    // reset value after select
    inputRef.current.value = "";
  };

  return (
    <div>
      <IconButton {...rest} onClick={() => inputRef.current.click()}>
        <AttachFileIcon />
      </IconButton>
      <input
        ref={inputRef}
        type="file"
        multiple
        onChange={handleOnChange}
        style={{ display: "none" }}
      />
    </div>
  );
};
