import * as Yup from "yup";

const ticketScheme = Yup.object().shape({
  estateId: Yup.number().required(),
  // subject: Yup.string().required(),
  body: Yup.string(),
  // place: Yup.string().required(),
  // public: Yup.bool(),
  // messagesEnabled: Yup.bool(),
  serviceType: Yup.string().required(),
});

export default ticketScheme;
