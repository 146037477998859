import React from "react";
import { compose } from "recompose";
import Toolbar from "@material-ui/core/Toolbar";
import DefaultAppBar from "../../components/DefaultAppBar";
import { injectIntl } from "react-intl";
import { Route } from "react-router-dom";
import { withCurrentEstate } from "../../functions";
import Typography from "@material-ui/core/Typography";
import { fullAddress } from "../../functions/users";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { IconButton } from "@material-ui/core";

function TicketDetailAppBar(props) {
  const { currentEstate } = props;
  const addressText = fullAddress(currentEstate.address);
  return (
    <Route
      render={({ history }) => {
        return (
          <DefaultAppBar>
            <Toolbar>
              <IconButton onClick={() => history.goBack()}>
                <ArrowBackIosIcon style={{ color: "white" }} />
              </IconButton>

              <div>
                <Typography color="inherit">{currentEstate.name}</Typography>
                <Typography variant="caption" color="inherit">
                  {addressText}
                </Typography>
              </div>
            </Toolbar>
          </DefaultAppBar>
        );
      }}
    />
  );
}

export default compose(injectIntl, withCurrentEstate())(TicketDetailAppBar);
