import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import { EstateToolbar } from "./Common";
import { withCurrentEstate } from "../functions";

class LocationToolBar extends React.Component {
  render() {
    const { currentEstate } = this.props;

    return currentEstate ? (
      <EstateToolbar estate={currentEstate} />
    ) : (
      <Toolbar> ... </Toolbar>
    );
  }
}

export default withCurrentEstate()(LocationToolBar);
