import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import LocationIcon from "@material-ui/icons/LocationOn";
import { fullAddress } from "../../functions/users";

const EstateToolbar = ({ estate }) => {
  const { address } = estate;
  if (address) {
    const addressText = fullAddress(address);
    return (
      <Toolbar>
        <div>
          <LocationIcon />
        </div>
        <div>
          <Typography color="inherit">{estate.name}</Typography>
          <Typography variant="caption" color="inherit">
            {addressText}
          </Typography>
        </div>
      </Toolbar>
    );
  }

  return null;
};

export default EstateToolbar;
