import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import LocationToolBar from "./../../components/LocationToolBar";
import AppBar from "./../../components/DefaultAppBar";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import ProfileHeader from "./ProfileHeader";
import {
  // withCurrentUser,
  withCurrentEstate,
} from "../../functions";

export const route = "/profile/account";

class AccountContainer extends Component {
  static route = route;
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <AppBar position="static">
          <LocationToolBar />
        </AppBar>
        <ProfileHeader estate={this.props.currentEstate} />
        Your Account
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    position: "relative",
    paddingTop: "3rem", // gap for fixed tab bar
    paddingBottom: "3rem", // gap for fixed bottom navigation
    // marginLeft: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  center: {
    display: "flex",
    margin: "50% auto",
    fontSize: "10rem",
  },
  button: {
    width: "100%",
  },
});

export default compose(
  withRouter,
  // withCurrentUser(),
  withCurrentEstate(),
  withStyles(styles),
)(AccountContainer);
