import React from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import { apiClient } from "../../apiClient/apiClient";
import SignInContainer from "../SignInContainer/SignInContainer";
import { logoutUser } from "../../modules/user/userActions";
import { withCurrentAccount } from "../../modules/accounts/accountHocs";

const styles = (theme) => ({
  layout: {
    paddingTop: theme.spacing(16),
    width: "auto",
    display: "block", // Fix IE11 ticket.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3,
    )}px`,
  },
  avatar: {
    margin: theme.spacing(),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE11 ticket.
    marginTop: theme.spacing(),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
});

const NewPasswordValidationScheme = Yup.object().shape({
  currentPassword: Yup.string().required(),
  newPassword: Yup.string().required(),
});

// Wrap our form with the using withFormik HoC
const formControl = {
  // Transform outer props into form values
  mapPropsToValues: (props) => ({
    currentPassword: "",
    newPassword: "",
  }),
  // Add a custom validation function (this can be async too!)
  validationSchema: NewPasswordValidationScheme,
  // Submission handler
  handleSubmit: async (
    values,
    { props, setSubmitting, setErrors, resetForm },
  ) => {
    const { currentPassword, newPassword } = values;
    const { accountId } = props.currentAccount;

    try {
      await apiClient().post(`/accounts/${accountId}/_updatePassword`, {
        currentPassword,
        newPassword,
      });
      // log user out
      props.dispatch(logoutUser());
      props.history.push(SignInContainer);
    } catch (err) {
      console.error(err);
    }
    resetForm();
  },
};

const UpdatePasswordForm = (props) => {
  const { classes, values, handleChange, handleSubmit, touched, errors } =
    props;

  const { formatMessage } = props.intl;

  return (
    <React.Fragment>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <form className={classes.form} onSubmit={handleSubmit}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="currentPassword">
                {formatMessage({ id: "app.reset.currentPassword" })}
              </InputLabel>
              <Input
                type="password"
                error={
                  touched.currentPassword && errors.currentPassword
                    ? true
                    : false
                }
                id="currentPassword"
                name="currentPassword"
                value={values.currentPassword}
                onChange={handleChange}
                autoComplete="currentPassword"
                autoFocus
              />
            </FormControl>

            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="newPassword">
                {formatMessage({ id: "app.reset.newPassword" })}
              </InputLabel>
              <Input
                type="password"
                error={touched.newPassword && errors.newPassword ? true : false}
                id="newPassword"
                name="newPassword"
                value={values.newPassword}
                onChange={handleChange}
                autoComplete="newPassword"
              />
            </FormControl>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary" //secondary / Primary
              className={classes.submit}
            >
              {formatMessage({ id: "app.reset.action.changePassword" })}
            </Button>
          </form>
        </Paper>
      </main>
    </React.Fragment>
  );
};

UpdatePasswordForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  withCurrentAccount(),
  connect(),
  withRouter,
  injectIntl,
  withFormik(formControl),
)(UpdatePasswordForm);
