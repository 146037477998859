import React from "react";
import { withRouter } from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import withStyles from "@material-ui/core/styles/withStyles";

import deLocaleData from "react-intl/locale-data/de";
import enLocaleData from "react-intl/locale-data/en";
import { IntlProvider, addLocaleData } from "react-intl";
import translations from "../../i18n/locales";

// theme
import { MuiThemeProvider } from "@material-ui/core/styles";
import { compose } from "recompose";
import Routes from "./Routes";
import NotificationComponent from "../../components/NotificationComponent";
import { connect } from "react-redux";
import getThemeById from "../../themes/getThemeById";

// init
addLocaleData([...enLocaleData, ...deLocaleData]);

const styles = (theme) => ({
  layout: {
    // width: hausifyTheme.overrides.bodyWidth,
    marginLeft: "auto",
    marginRight: "auto",
    height: "100%",
    display: "block", // Fix IE11 ticket.
  },
});

function getLocaleByPropertyManagementId(id) {
  switch (id) {
    case 7:
      return "de_custom";
    default:
      return "de";
  }
}

const App = (props) => {
  // use a custom language file or use de if there is no customization
  const propertyManagementId = props.propertyManagement.id;
  // handle theme
  const theme = getThemeById(propertyManagementId);
  // handle locale
  const localeKey = getLocaleByPropertyManagementId(propertyManagementId);
  const messages = translations[localeKey];
  const [locale] = localeKey.split("_");

  return (
    <IntlProvider locale={locale} key={locale} messages={messages}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Routes />
        <NotificationComponent />
      </MuiThemeProvider>
    </IntlProvider>
  );
};

const mapStateToProps = ({ propertyManagement }) => ({ propertyManagement });

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps)
)(App);
