import { apiClient } from "../../apiClient/apiClient";

let timer;

// actions
export const FETCH = "postings/FETCH";
export const FETCH_BY_ESTATE_ID = "postings/FETCH_BY_ESTATE_ID";

// action creators
export const fetchPostingsAction = (postings) => ({
  type: FETCH,
  payload: postings,
});

// The following schema:

// attachments: []
// attachmentsEnabled: true
// author: "Eva-Maria Freund"
// authorAccountId: 1
// body: "An alle Bewohner:↵↵Der Fahrradkeller wird aufgrund von Umbaumaßnahmen für die Zeit vom 28.09. 7:00 Uhr bis zum 13.10.2018 0.00 Uhr gesperrt sein.↵↵Die Räume werden neu aufgeteilt und erweitert, um dem erhöhten Fahrradaufkommen Herr zu werden.↵↵Bitte entfernen Sie alle Fahrräder bis zum 26.09. 18:00 Uhr. Ausnahmsweise können Sie ihre Fahrräder im Innenhof abstellen, allerdings so, dass jederzeit die Fluchtwege frei bleiben.↵↵Vielen Dank für ihr Verständnis,↵ihre HV"
// createdAt: "2018-09-24T12:46:13.640Z"
// estateId: 7
// id: 15
// livingUnitIds: [18]
// messages: []
// messagesEnabled: false
// publishFrom: "2018-09-24T13:00:00Z"
// publishTo: "2018-10-13T22:00:00Z"
// published: false
// publishingMode: "AUTO"
// subject: "Fahrradkeller Umbau"
// type: "Posting"
export const fetchByEstateIdPostingsAction = (postings) => {
  const payload = postings && Array.isArray(postings) ? postings : [postings];

  return {
    type: FETCH_BY_ESTATE_ID,
    payload,
  };
};

// operations
export function fetchPostingsThunk() {
  return async (dispatch) => {
    const response = await apiClient().get(`communications/postings`, {
      params: {
        sort: '["createdAt","DESC"]',
      },
    });

    if (response && response.data) {
      dispatch(fetchPostingsAction(response.data));
    }
  };
}

/**
 * Fetch postings by estade.
 * @param {*} estateId
 */
export function fetchPostingsByEstateId(estateId) {
  return async (dispatch) => {
    // TODO: Check whether this is the correct way to ask for postings
    const response = await apiClient().get(`communications/postings`, {
      params: {
        sort: '["createdAt","DESC"]',
        filter: `{"estateId": ${estateId}}`,
      },
    });

    if (response && response.data) {
      dispatch(fetchByEstateIdPostingsAction(response.data));
    }

    // TODO: recursion should not be part of the operation
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(fetchPostingsByEstateId(estateId));
    }, 15000);
  };
}
