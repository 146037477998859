import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import PropertyManagementProfile from "./PropertyManagementProfile";

const styles = (theme) => ({
  root: {
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
  },
});

class PropertyManagementNavigationContainer extends React.Component {
  render() {
    const { classes, propertyManagement } = this.props;
    return (
      <div className={classes.root}>
        <PropertyManagementProfile estate={propertyManagement} />
      </div>
    );
  }
}

PropertyManagementNavigationContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = ({ propertyManagement }) => {
  return {
    propertyManagement,
  };
};

export default compose(
  connect(mapStateToProps),
  withStyles(styles, { withTheme: true }),
)(PropertyManagementNavigationContainer);
