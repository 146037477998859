export const ASSETS_BASE_URL = process.env.REACT_APP_API;

/**
 *
 * @param {*} src
 */
export const getImageSRC = (src) => {
  return src.indexOf("://") === -1 ? `${ASSETS_BASE_URL}${src}` : src;
};

/**
 *
 * @param {*} type
 */
export const getMimeType = (type) => {
  if (type) {
    const temp = type.split("/");
    return temp.pop();
  }
};
