import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// import TextField from "@material-ui/core/TextField";
import { branch, renderComponent, compose } from "recompose";
import { fullName, fullAddress } from "../../functions/users";
// import { fullEmail } from "../../functions/users";
import { injectIntl } from "react-intl";
// import { Input } from "@material-ui/core";

const NoUser = (props) => (
  <React.Fragment>
    <Typography variant="body1" gutterBottom>
      Profile
    </Typography>
    <div>No user available</div>
  </React.Fragment>
);

const hasCurrentUser = ({ currentUser }) => Boolean(currentUser == null);

const noUserAvailable = branch(hasCurrentUser, renderComponent(NoUser));

class SimpleProfile extends React.PureComponent {
  render() {
    const formatMessage = this.props.intl.formatMessage;
    const currentUser = this.props.currentUser;
    const { address } = currentUser;
    const userFullName = fullName(currentUser, " ");
    const addressLine = fullAddress(address);

    return (
      <React.Fragment>
        <Grid container spacing={24} style={{ textAlign: "center" }}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h5">
              {formatMessage({ id: "profile.header.headline" })}
            </Typography>
            <Typography variant="h5">{userFullName}</Typography>
            <Typography variant="subtitle1" style={{ marginBottom: "20px" }}>
              {addressLine}
            </Typography>

            {/* <Typography variant="subtitle1" style={{ marginBottom: "20px" }}>
               {emailLine}
            </Typography> */}

            <Typography component="p">
              {formatMessage({ id: "profile.header.paragraph" })}
            </Typography>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default compose(noUserAvailable, injectIntl)(SimpleProfile);
