import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MessageListItem from "../../components/MessageListItem";
import { withRouter } from "react-router-dom";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import { compose } from "recompose";
import { hasEntries, nonOptimalStates } from "./../../functions";
import MitteilungenContainer from "../HomeContainer/MitteilungenContainer";

const styles = (theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
    marginBottom: "1rem",
  },
  listItem: {
    lineHeight: "unset",
  },
  center: {
    display: "flex",
    margin: "50% auto",
    fontSize: "10rem",
  },
});

export class TicketListContainer extends Component {
  handleOpenTicket = (id) => {
    this.props.onItemClick(id);
    this.props.history.push(`/tickets/${id}`);
  };

  render() {
    return (
      // ! MitteilungenContainer is misleading we should call it TicketListContainer because it handles tickets
      // TODO: consider renaming MitteilungenContainer to TicketListContainer
      <MitteilungenContainer
        onClick={this.handleOpenTicket}
        light
        disablePadding={this.props.disablePadding}
      />
    );
  }
}

TicketListContainer.propTypes = {
  listItem: PropTypes.func,
  history: PropTypes.object.isRequired,
};

TicketListContainer.defaultProps = {
  listItem: MessageListItem,
};


const hasNoTickets = ({ tickets }) => !hasEntries(tickets);
const NoTicketsAnnouncement = (props) => (
  <AnnouncementIcon color="disabled" className={props.classes.center} />
);

// when hasNoTickets is true, render NoTicketsAnnouncement
const TicketList = nonOptimalStates([
  {
    when: hasNoTickets,
    render: NoTicketsAnnouncement,
  },
])(TicketListContainer);

/**
 *
 */
export default compose(withStyles(styles), withRouter)(TicketList);
