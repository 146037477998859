// fixed translations
// prettier-ignore
const serviceTypes = [
  { id: 28, name: "Aufzug", i18n: "servicetypes.elevator" },
  { id: 29, name: "Beleuchtung Gemeinschaftsfläche", i18n: "servicetypes.lighting_communal_area", },
  { id: 30, name: "Beschlussantrag", i18n: "servicetypes.resolution_request" },
  { id: 31, name: "Gebäudereinigung", i18n: "servicetypes.building_cleaning_caretaker", },
  { id: 32, name: "Graffiti", i18n: "servicetypes.graffiti" },
  { id: 33, name: "Heizung", i18n: "servicetypes.heater" },
  { id: 34, name: "Müllplatz", i18n: "servicetypes.garbage_area" },
  { id: 35, name: "Rohrbruch", i18n: "servicetypes.pipe_break" },
  { id: 36, name: "Sperrmüll", i18n: "servicetypes.bulky_waste" },
  { id: 37, name: "Winterdienst", i18n: "servicetypes.winter_service" },
  { id: 38, name: "Forderungen & Zahlungen", i18n: "servicetypes.claims_payments", },
  { id: 39, name: "Änderung der Kontaktdaten", i18n: "servicetypes.change_contact_details", },
  { id: 40, name: "Lob & Kritik", i18n: "servicetypes.praise_criticism" },
  { id: 41, name: "Mieterwechsel", i18n: "servicetypes.tenant_change" },
  { id: 42, name: "Schlüsselbestellung", i18n: "servicetypes.key_order" },
  { id: 44, name: "Allgemein", i18n: "servicetypes.general" },
  { id: 47, name: 'Schlüsseldienst', i18n: 'servicetypes.locksmith' },
  { id: 128, name: "Elevator", i18n: "servicetypes.elevator" },
  { id: 133, name: "Heater", i18n: "servicetypes.heater" },
  { id: 137, name: "Service snow removal", i18n: "servicetypes.winter_service", },
  { id: 138, name: "Claims & Payments", i18n: "servicetypes.claims_payments" },
  { id: 144, name: "General", i18n: "servicetypes.general" },
  { id: 145, name: "Reparaturen & Erhaltung", i18n: "servicetypes.maintenance" },
  { id: 146, name: "Beschaffung", i18n: "servicetypes.purchasing" },
  { id: 147, name: "Instandhaltung/Sanierung", i18n: "servicetypes.redevelopment" },
  { id: 148, name: "Hof- und Anlagenpflege", i18n: "servicetypes.garden" },
  { id: 149, name: "EDV / IT", i18n: "servicetypes.it" },


];

export const getServiceTypeLanguageIdByName = (name) => {
  const type = serviceTypes.find((sType) => sType.name === name);
  if (type) {
    return type.i18n;
  } else {
    return "servicetypes.not_available";
  }
};

export const TICKET_STATES = {
  NEW: "NEW",
  ACCEPTED: "ACCEPTED",
  IN_PROGRESS: "IN_PROGRESS",
  WORK_DONE: "WORK_DONE",
  SOLVED: "SOLVED",
};
