import React from "react";
import LayoutContentCenteredWithBackground from "../../components/Layouts/ContentCenteredWithBackgroundLayout";
import NewPasswordForm from "./NewPasswordForm";

const NewPasswordView = () => (
  <LayoutContentCenteredWithBackground>
    <NewPasswordForm />
  </LayoutContentCenteredWithBackground>
);
NewPasswordView.route = `/new-password/:id`;

export default NewPasswordView;
