import customThemeForId7 from "./customThemeForId7";
import customThemeForId151 from "./customThemeForId151"; // HV Prod (Demo)
import customThemeForId941 from "./customThemeForId941"; // PeBo
import customThemeForId1060 from "./customThemeForId1060"; // FEWA
import customThemeForId923 from "./customThemeForId923"; // FeSt Grundstücksverwaltung
import customThemeForId753 from "./customThemeForId753"; // FeSt Grundstücksverwaltung
import customThemeForId1254 from "./customThemeForId1254"; // VID
import customThemeForId1462 from "./customThemeForId1462"; // Bootkitas
import hausifyTheme from "./hausifyTheme";

export default function getThemeById(id) {
  switch (id) {
    case 7:
      return customThemeForId7;

    case 151: // HV Prod (Demo)
      return customThemeForId151;

    case 923: // FeSt Grundstücksverwaltung
      return customThemeForId923;

    case 941: // PeBo
      return customThemeForId941;

    case 1060: // PeBo
      return customThemeForId1060;

    case 1254: // VID
      return customThemeForId1254;

    case 753: // Flat-mate
      return customThemeForId753;

    case 57: // 57 existiert nicht auf PROD, nur STAGE: stageverwaltung1@hausify.de
      return customThemeForId151;

    case 1462: // bootkitas
      return customThemeForId1462;

    default:
      return hausifyTheme;
  }
}
