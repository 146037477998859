import React from "react";
import WarningIcon from "@material-ui/icons/ErrorOutline";
import SettingsIcon from "@material-ui/icons/Settings";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {
  NEW,
  IN_PROGRESS,
  // ACCEPTED,
  SOLVED,
  WORK_DONE,
} from "../../functions";

/**
 *
 * @param {*} status
 */
export const getProcessIconByProcessingState = (status) => {
  const baseProps = {
    style: {
      fontSize: "small",
    },
  };
  switch (status) {
    case NEW:
      return <WarningIcon {...baseProps} style={{ color: "#FF1B1C" }} />;
    // case ACCEPTED:
    //   return <ArrowIcon
    //     {...iconProps}
    //     style={{
    //       ...iconProps.style,
    //       color: blue[500]
    //     }}
    //   />;
    case IN_PROGRESS:
      return <SettingsIcon {...baseProps} style={{ color: "#FCA921" }} />;
    case WORK_DONE:
    case SOLVED:
      return <CheckCircleIcon {...baseProps} style={{ color: "#7FB800" }} />;
    default:
  }
};
