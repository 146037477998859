const { apiClient } = require("../../apiClient/apiClient");

const ENDPOINT = "/communications/tickets";

/*
ticket
*/

// read
export async function fetchTicketAsync(id) {
  const response = await apiClient().get(`${ENDPOINT}/${id}`);
  return response.data;
}

// create
export async function createTicketAsync(data) {
  const response = await apiClient().post(ENDPOINT, data);
  return response.data;
}

// update
export async function updateTicketAsync(id, data) {
  const response = await apiClient().put(`${ENDPOINT}/${id}`, data);
  return response.data;
}

// delete
export async function deleteTicketAsync(id) {
  const response = await apiClient().delete(`${ENDPOINT}/${id}`);
  return response.data;
}
