import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { fetchAssigneesByEstateThunk } from "./assigneeListOperations";

const assigneeListAdapter = createEntityAdapter();

const assigneeListSlice = createSlice({
  name: "assigneeList",
  initialState: assigneeListAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAssigneesByEstateThunk.fulfilled, (state, action) => {
      assigneeListAdapter.setAll(state, action.payload);
    });
  },
});

const selectors = assigneeListAdapter.getSelectors(
  (state) => state.tickets.ticketDetail.assignees
);
const selectIds = (_state, itemIds) => itemIds;
const selectByIds = createSelector(
  [selectors.selectAll, selectIds],
  (state, itemIds = []) => {
    const assignees = state.filter((assignee) => itemIds.includes(assignee.id));
    return assignees;
  }
);

export const assigneeListSelectors = { ...selectors, selectByIds };

export const reducer = assigneeListSlice.reducer;
