/**
 * Messages which are private can be seen by
 * the estate and the person who send of the
 * ticket.
 *
 * you need
 * - accessibleByPersonIds (mostly an array with only one user id)
 * - publishingMode = "UNPUBLISHED"
 */

// we have to deal with this information in several places in frontend
export const ticketIsPrivate = (ticket) => {
  const isPrivate = Boolean(
    ticket && ticket.publishingMode && ticket.publishingMode === "UNPUBLISHED",
  );
  return isPrivate;
};
