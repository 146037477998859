import React from "react";
import { Modal } from "react-responsive-modal";
import PdfViewer from "./PdfViewer";

// import testpdf from './4561942.pdf';

export const PdfModal = ({ source, closeModal }) => {
  return (
    <Modal
      open
      onClose={closeModal}
      center
      styles={{ modal: { overflow: "auto" } }}
      showCloseIcon={false}
    >
      <PdfViewer fileName={source} onClose={closeModal} />
    </Modal>
  );
};
